<template>
  <div
    id="wOPRules"
    class="Rules hidden"
  >
    <div class="closeBtn">
      <img
        src="/assets/Icons/close.svg"
        alt=""
        class="redBtn"
        @click="helpmenu()"
      >
    </div>
    <h4 class="font-bold">
      Wahrheit oder Pflicht Spielregeln
    </h4><br>
    <span class="font-normal">Wähle zwischen Wahrheit, Pflicht und Zufall aus. Bei „Wahrheit“ wird dem Ausgewählten eine Frage gestellt, die er wahrheitsgemäß beantworten muss; kommt jedoch eine „Pflicht“, muss der Ausgewählte die Aufgabe absolvieren. Sollte sich ein Spieler weigern, die Aufgabe zu erfüllen oder die Frage zu beantworten, muss dieser 2 Schlücke trinken. Danach ist der nächste Spieler an der Reihe.
    </span><br><br>
  </div>
  <div
    id="wOPstufenAbfrage"
    class="theme-drinkorlose"
  >
    <header>
      <div class="t-left pad-16">
        <router-link to="/gameOverview">
          <img
            src="../assets/Icons/back.svg"
            class="width-32"
            style="filter: invert(1)"
          >
        </router-link>
      </div>
      <div class="pad-16" />
    </header>
    <div class="spielStufen">
      <div
        id="stufe1"
        class="stufenImg stufeFun"
        @click="getStufe('stufe1')"
      >
        <div class="StufenBeschreibung">
          <h3>Text für Spießer und Vergebene</h3>
          <h2>Warm Up</h2>
        </div>
        <img
          src="../assets/Pictures/emojis/flexed-biceps.png"
          class="width-64 stufenIcon"
        >
      </div>
      <div
        id="stufe2"
        class="stufenImg stufeDirty"
        @click="getStufe('stufe2')"
      >
        <div class="StufenBeschreibung">
          <h3>Für den aufregenden Spaß</h3>
          <h2>Dirty</h2>
        </div>
        <img
          src="../assets/Pictures/emojis/fire.png"
          class="width-64 stufenIcon"
        >
      </div>
      <div
        id="stufe3"
        class="stufenImg stufeLoveGame"
        @click="getStufe('stufe3')"
      >
        <div class="StufenBeschreibung">
          <h3>Hier darfst du keine Hemmungen haben</h3>
          <h2>Erotisch</h2>
        </div>
        <img
          src="../assets/Pictures/emojis/heart-on-fire.png"
          class="width-64 stufenIcon"
        >
      </div>
    </div>
    <div class="pad-16 t-center">
      <button
        @click="helpmenu()"
        class="button large secondary"
      >
        Spieleanleitung
      </button>
    </div>
  </div>
  <section
    id="wahrheit-pflicht-game"
    class="hidden"
  >
    <header class="GameHeader grid-12">
      <div class="col-sp-6 row-sp-12 t-left">
        <img
          src="/assets/Icons/help.svg"
          alt=""
          class="helpsymbol"
          @click="helpmenu()"
        >
      </div>
      <div class="col-sp-6 row-sp-12 t-right">
        <router-link
          to="gameOverview"
          class="backCloseButton"
        >
          <img
            src="/assets/Icons/close.svg"
          >
        </router-link>
      </div>
    </header>
    <div class="headline">
      <h1 class="font-bold">
        {{ Title }}
      </h1>
    </div>
    <h3
      id="WoPtoDo"
      class="font-normal"
    >
      Drücke einen der Button um zu starten
    </h3>
    <div class="gameMenu">
      <div class="btns">
        <button
          id="wahrheit-btn"
          @click="wahrheitBtn()"
        >
          Wahrheit
        </button>
        <p class="pad-8 font-normal">
          oder
        </p>
        <button
          id="pflicht-btn"
          @click="pflichtBtn()"
        >
          Pflicht
        </button>
      </div>
      <div class="btns">
        <button
          id="zufall-btn"
          @click="zufallsBtn()"
        >
          Zufall
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {authStore} from "@/store/authStore";

let stufe = null
import { gsap } from 'gsap';
import json from '@/jsonDaten/WoP.JSON'
import {addStore} from "@/store/addStore";
const WoPTL = new gsap.timeline()
export default {
  name:"WahrheitOderPflicht",
  Title:"Wahrheit oder Pflicht",
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
    data(){
        return{
            WoPDaten : json,
          Title: 'Wahrheit oder Pflicht'
        }
    },
    methods: {
        getStufe : function(value){
            stufe = value
            console.log(stufe)
            switch(stufe){
                case "stufe2":
                    if(this.auth.premium){
                        break;
                    }
                    else{
                        console.log("kauf dir Premium");
                        return
                    }
                case "stufe3":
                    if(this.auth.premium){
                        break;
                    }
                    else{
                        console.log("kauf dir Premium");
                        return
                    } 
            }
            document.querySelector("#wOPstufenAbfrage").classList.add("hidden")
            document.querySelector("#wahrheit-pflicht-game").classList.remove("hidden")
            return stufe
        },
        helpmenu : function(){
            document.querySelector("#wOPRules").classList.toggle("hidden");
        },
        wahrheitBtn : function(){
            this.renderElements(this.WoPDaten,"wahrheit", stufe )
            this.Title = 'Wahrheit'
        },
        pflichtBtn : function(){
            this.renderElements(this.WoPDaten,"pflicht", stufe )
            this.Title = 'Pflicht'
        },
        zufallsBtn : function(){
            const randomizer = Math.round(Math.random() );
            if(randomizer == 0){
                this.wahrheitBtn()
            }
            else if(randomizer == 1){
                this.pflichtBtn()
            }
        },

        renderElements : function(jsonDaten, task, stufe){
            const toDo = document.querySelector("#WoPtoDo")
            function randomContent(AllContent){
                if(AllContent.length <= 0){
                    document.querySelector("wahrheitOderPflichtBanner").classList.add("hidden")
                    document.querySelector("endcardBanner").classList.remove("hidden")
                }
                const randomNumber = [Math.floor(Math.random()*AllContent.length)]
                let question = AllContent[randomNumber]
                AllContent.splice(randomNumber, 1)
                console.log(AllContent.length)
                toDo.innerHTML = question
            }
            if(task == "wahrheit"){
                switch (stufe){
                    case "stufe1":
                        randomContent(jsonDaten.Wahrheit.stufe1.Fragen)
                        break;
                    case "stufe2":
                        randomContent(jsonDaten.Wahrheit.stufe2.Fragen)
                        break;
                    case "stufe3":
                        randomContent(jsonDaten.Wahrheit.stufe3.Fragen)
                        break;
                }
            }
            else if(task == "pflicht"){
                switch (stufe){
                    case "stufe1":
                        randomContent(jsonDaten.Pflicht.stufe1.Pflichten)
                        break;
                    case "stufe2":
                        randomContent(jsonDaten.Pflicht.stufe2.Pflichten)
                        break;
                    case "stufe3":
                        randomContent(jsonDaten.Pflicht.stufe3.Pflichten)
                        break;

                }    
            }
        }
    }
}
</script>

<style scoped>

.headline h1{
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    word-spacing: 5px;
    height: 30px;
    white-space: nowrap;
    margin-bottom: 16px;
}
#wahrheit-pflicht-game{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;

}
.btns{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80vw;

}
.btns button{
  cursor: pointer;
}
#wahrheit-btn{
    background-color: #545C8F;
    width: 210px;
    height: 80px;
    border-style: none;
    color: white;
    font-size: 26px;
    font-weight: bold;
    border-radius: 7px;

}
#pflicht-btn{
    background-color: #FFA301;
    width: 210px;
    height: 80px;
    border-style: none;
    color: white;
    font-size: 26px;
    font-weight: bold;
    border-radius: 7px;
}
#wahrheit-pflicht-game p{
    color: white;
    font-size: 20px;
    font-weight: lighter;
}
#zufall-btn{
    background: linear-gradient(90deg, rgba(84,92,143,1) 0%, rgba(84,92,143,1) 50%, rgba(255,163,1,1) 50%);
    font-size: 20px;
    font-weight: bold;
    color: white;
    border: none;
    padding: 12px 50px;
    border-radius: 8px;
    cursor: pointer;
  margin-top: 16px;
}
#WoPtoDo {
    color: white;
    margin: 0px 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}
.back-arrow{
    position: absolute;
    top: 10px;
    left: 10px;
    filter: invert(1);
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}
</style>