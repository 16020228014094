<template>
  <header class="GameHeader grid-12">
    <div class="col-sp-6 row-sp-12 t-left">
      <img
        src="/assets/Icons/help.svg"
        alt=""
        class="helpsymbol"
        @click="helpmenu()"
      >
      <div
        id="tODRules"
        class="Rules hidden"
      >
        <div class="closeBtn">
          <img
            src="../assets/Icons/close.svg"
            alt=""
            class="redBtn"
            @click="helpmenu()"
          >
        </div>
        <h4 class="font-bold">
          Twink or Daddy Spielregeln
        </h4><br>
        <span class="font-normal">Wähle zwischen Twink oder Daddy aus. Per Zufall wird dir eine Aufgabe oder Frage gestellt, die du absolvieren musst. Sollte sich ein Spieler weigern, die Aufgabe zu erfüllen oder die Frage zu beantworten, muss diese Person 2 Schlücke trinken, falls nicht anders in der Aufgabe vorgegeben. Danach ist der nächste Spieler an der Reihe.
        </span><br><br>
      </div>
    </div>
    <div class="col-sp-6 row-sp-12 t-right">
      <router-link
        to="gameOverview"
        class="backCloseButton"
      >
        <img
          src="/assets/Icons/close.svg"
        >
      </router-link>
    </div>
  </header>
  <section id="TwinkOrDaddy">
    <div id="premiumAcc" />
    <div id="ToD_Game">
      <div class="ToDheadline">
        <h1 class="font-bold">
          {{ Title }}
        </h1>
      </div>
      <h3
        id="ToD_toDo"
        class="font-bold"
      >
        Was bist du ?
      </h3>
      <div class="gameMenu">
        <div class="btns">
          <button
            id="twink_btn"
            @click="renderToD(ToDDaten ,'twink')"
          >
            Twink
          </button>
          <p class="font-normal pad-8">
            or
          </p>
          <button
            id="daddy_btn"
            @click="renderToD(ToDDaten ,'daddy')"
          >
            Daddy
          </button>
        </div>
        <button
          id="zufall-btn"
          @click="zufallsBtn()"
        >
          Zufall
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import json from '@/jsonDaten/TwinkOrDaddy.json';
import { gsap } from 'gsap';
import {addStore} from "@/store/addStore";
import {authStore} from "@/store/authStore";
const ToDTL = new gsap.timeline()
export default {
  name: "TwinkOrDaddy",
  title: "Twink or Daddy",
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
    data(){
        return{
            stufe : 1,
            ToDDaten : json,
            Title: 'Twink or Daddy'
        }
    },
    async mounted(){
        if(this.stufe === 1){
            document.querySelector("#premiumAcc").classList.add("hidden")}
    },
    methods: {
        helpmenu : function(){
            document.querySelector("#tODRules").classList.toggle("hidden");
        },
        renderToD : function(renderElements, task){
            const ToD_toDo = document.querySelector("#ToD_toDo")
            function randomContent(AllContent){
                let content = AllContent
                if(AllContent.length <= 0){
                    document.querySelector("twinkOrDaddyBanner").classList.add("hidden")
                    document.querySelector("endcardBanner").classList.remove("hidden")
                }
                const randomNumber = [Math.floor(Math.random()*content.length)]
                let question = content[randomNumber]
                content.splice(randomNumber, 1)
                ToD_toDo.innerHTML = question
            }
            if(task == "twink"){
                randomContent(renderElements.Twink)
                this.Title = 'Twink'
            }
            else if(task == "daddy"){
                randomContent(renderElements.Daddy)
                this.Title = 'Daddy'
            }
        },
        zufallsBtn : function(){
            const randomizer = Math.round((Math.random()) );
            if(randomizer === 0){this.renderToD(this.ToDDaten ,'twink')}
            else if(randomizer === 1){this.renderToD(this.ToDDaten ,'daddy')}
        }
    }
}
    // this.checkStatus()
</script>

<style scoped>


#ToD_Game{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
}
.ToDheadline h1 {
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    word-spacing: 5px;
    height: 32px;
    white-space: nowrap;
    margin-bottom: 16px;
}
#twink_btn , #daddy_btn {
    height: 80px;
    width: 210px;
    border-radius: 7px;
    border-style: none;
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    color: white;
}
#twink_btn {
    background-color: #545C8F;
}
#daddy_btn {
    background-color: #FFA301;
}
.btns {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80vw;
}
.btns button{
  cursor: pointer;
}
.btns p {
    color: white;
    font-size: 20px;
    font-weight: lighter;
}
#zufall-btn{
    background: linear-gradient(90deg, rgba(84,92,143,1) 0%, rgba(84,92,143,1) 50%, rgba(255,163,1,1) 50%);
    font-size: 20px;
    font-weight: bold;
    color: white;
    border: none;
    padding: 12px 50px;
    border-radius: 8px;
    cursor: pointer;
  margin-top: 16px;
}
#ToD_toDo {
    color: white;
    margin: 0px 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}
#zufall_btn {
    background: linear-gradient(90deg, rgba(84,92,143,1) 0%, rgba(84,92,143,1) 50%, rgba(255,163,1,1) 50%);
    font-size: 20px;
    font-weight: bold;
    color: white;
    border: none;
    padding: 12px 50px;
    border-radius: 8px;
    cursor: pointer;
}

/* #premiumAcc {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
} */
</style>