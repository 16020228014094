<template>
  <div
    id="entwederOderRules"
    class="Rules hidden"
  >
    <div class="closeBtn">
      <img
        src="../assets/Icons/close.svg"
        alt=""
        class="redBtn"
        @click="helpmenu()"
      >
    </div>
    <h4 class="font-bold">
      Entweder oder Spielregeln
    </h4><br>
    <span class="font-normal">Das Spiel beginnt. Euch wird eine Aufgabe gestellt. Entscheidet jeweils für euch selber. Es kommt zur Abstimmung. Das Team, welches weniger Stimmen für seine Entscheidung erhalten hat und somit in der Minderheit liegt, muss 2 Schlücke trinken. Die Abstimmung kann zB. für Antwort A mit einem Daumen Hoch und für Antwort B mit einem Daumen runter erfolgen.
    </span><br><br>
  </div>
  <div
    id="entwederOderStufenabfrage"
    class="theme-drinkorlose"
  >
    <div id="stufenAbfrage">
      <header>
        <div class="t-left pad-16">
          <router-link to="/gameOverview">
            <img
              src="../assets/Icons/back.svg"
              class="width-32"
              style="filter: invert(1)"
            >
          </router-link>
        </div>
        <div class="pad-16" />
      </header>
      <div class="spielStufen">
        <div
          id="stufe1"
          class="stufenImg stufeFun"
          @click="getStufe('stufe1')"
        >
          <div class="StufenBeschreibung">
            <h3>Für den perfekten Einstieg</h3>
            <h2>Spaß</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/face-with-hand-over-mouth.png"
            class="width-64 stufenIcon"
          >
        </div>
        <div
          id="stufe2"
          class="stufenImg stufeDirty"
          @click="getStufe('stufe2')"
        >
          <div class="StufenBeschreibung">
            <h3>Hier gehts zur Sache</h3>
            <h2>Dirty</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/fire.png"
            class="width-64 stufenIcon"
          >
        </div>
      </div>
      <div class="pad-16 t-center">
        <button
          @click="helpmenu()"
          class="button large secondary"
        >
          Spieleanleitung
        </button>
      </div>
    </div>
  </div>
  <section
    id="entwederOderGame"
    class="hidden"
  >
    <header class="GameHeader grid-12">
      <div class="col-sp-6 row-sp-12 t-left">
        <img
          src="/assets/Icons/help.svg"
          alt=""
          class="helpsymbol"
          @click="helpmenu()"
        >
      </div>
      <div class="col-sp-6 row-sp-12 t-right">
        <router-link
          to="gameOverview"
          class="backCloseButton"
        >
          <img
            src="/assets/Icons/close.svg"
          >
        </router-link>
      </div>
    </header>
    <h1 class="headline">
      Entweder oder
    </h1>
    <h3 id="entwederOderTask">
      Drücke auf Weiter zum starten
    </h3>
    <div class="gameMenu">
      <button
        id="entwederOderBtn"
        @click="startGame()"
      >
        Weiter
      </button>
    </div>
  </section>
</template>

<script>
import json from '@/jsonDaten/entwederOder.json'
import {authStore} from "@/store/authStore";
let stufe = null
export default {
  name: "EntwederOder",
  title: "Entweder oder",
  setup() {
    const auth = authStore()

    return {auth}
  },
  data(){
    return{
      entwederOderDaten : json
    }
  },
  methods: {
    getStufe : function(value){
      stufe = value
      if(stufe === "stufe2"){
        if(this.auth.premium){
          console.log("Premium vorhanden")
          document.querySelector("#entwederOderStufenabfrage").classList.add("hidden")
          document.querySelector("#entwederOderGame").classList.remove("hidden")
          return
        }
        else{
          console.log("kauf dir Premium");
          return
        }
      }
      console.log(stufe)
      document.querySelector("#entwederOderStufenabfrage").classList.add("hidden")
      document.querySelector("#entwederOderGame").classList.remove("hidden")
      return stufe
    },
    startGame : function (){
      function randomContent(AllContent){

        if(AllContent.length <= 0){
          document.querySelector("entwederOderBanner").classList.add("hidden")
          document.querySelector("endcardBanner").classList.remove("hidden")
        }
        const randomNumber = [Math.floor(Math.random()*AllContent.length)]
        let question = AllContent[randomNumber]

        AllContent.splice(randomNumber, 1)
        document.querySelector("#entwederOderTask").innerHTML = question
      }
      switch (stufe){
        case "stufe1":
          randomContent(this.entwederOderDaten.stufe1)
          break;
        case "stufe2":
          randomContent(this.entwederOderDaten.stufe2)
          break;
      }
    },
    helpmenu : function(){
      document.querySelector("#entwederOderRules").classList.toggle("hidden");
    },
    backToHome : function(){
      location.reload();
    }
  }
}
</script>

<style scoped>
#entwederOderStufenabfrage{
  position: absolute;
  z-index: 20;
  width: 100%;
  height: fit-content;
  background: rgba(0, 0, 0, 0.0);
}

#entwederOderGame{
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
}
#entwederOderTask , h1{
  font-family: 'open sans';
  color: white;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}
#entwederOderTask{
  font-size: 24px;
  padding: 16px;
}
#entwederOderGame button{
  width: 130px;
  height: 35px;
  color: white;
  background-color: #FFA301;
  border-radius: 7px;
  border-style: none;
  font-size: 16px;
  font-family: 'open sans';
  font-weight: bold;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}
</style>