import { defineStore } from 'pinia'

const error = new ErrorEvent('doof')

export const authStore = defineStore('auth', {
    state: () => ({
        premium: localStorage.getItem('premium') ? JSON.parse(localStorage.getItem('premium') ) : false,
        item: {
            id: 'lalala',
            alias: 'premiusm'
        },
        product: {},
        buyModal: false
    }),
    actions: {
        checkPremium(){
            this.premium = false
        },
        initStore(){
            if (!window.store) {
                console.log('Store not available');
                return;
            }

            store.verbosity = store.INFO;
            store.validator = "https://validator.20nort.de/v1"

            store.register({
                id:    this.item.id,
                alias: this.item.alias,
                type:   store.NON_CONSUMABLE
            });

            store.error(function(error) {
                console.log('ERROR ' + error.code + ': ' + error.message);
            });

            store.when(this.item.alias).updated((p) => {
                this.product = p
            });
            store.when(this.item.alias).approved((p) => {
                p.verify();
            });
            store.when(this.item.alias).verified((p) => {
                p.finish();
            });

            store.refresh();
        },
        purchasePremium() {
            store.order(this.item.alias);
        },
    }
})