import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import title from '@/mixins/title'
import { createPinia } from 'pinia'
import modal from "@/components/basic/modal";

const pinia = createPinia();
pinia.use(({ store }) => {
    store.$router = Vue.markRaw(router)
});

const vueProto = Vue.createApp(App).use(pinia).use(router).use(VueAxios, axios).mixin(title)
vueProto.component('VueModal', modal)
vueProto.config.warnHandler = () => null;

router.isReady().then(() => {
    if(process.env.CORDOVA_PLATFORM) {
        document.addEventListener("deviceready", () => {
            vueProto.mount('#App')
        }, false);
    }else{
        vueProto.mount('#App')
    }
})
