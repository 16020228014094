<template>
  <main class="theme-drinkorlose">
    <header>
      <div class="cover t-center">
        <img
          src="assets/Pictures/logo.png"
          class="width-350"
        >
      </div>
    </header>
    <section class="t-center color-white">
      <h6
        class="f-light"
        style="font-size: 1em; opacity: 0.8"
      >
        Für einen unvergesslichen Abend
      </h6>
      <h1>Schnellstart</h1>
      <div class="pad-16" />
    </section>
    <section>
      <div
        class="gameBox br-24 grid-12 pad-24 pad-top-24"
        style="background-color: var(--dol-green);"
        @click="goToGame('drinkOrLose')"
      >
        <div class="col-sp-9 row-sp-12 color-white">
          <h6 class="f-light">
            Der Kinky Spaß am Abend
          </h6>
          <h3>Drink! Or Lose</h3>
        </div>
        <div class="col-sp-3 row-sp-12 t-right">
          <img
            src="../assets/Pictures/emojis/cocktail.png"
            class="width-64"
          >
        </div>
      </div>
    </section>
    <section class="pad-32 t-center">
      <router-link
        to="gameOverview"
        class="button large primary"
        style="padding: 8px 48px;"
      >
        Alle Spiele
      </router-link>
      <div class="pad-16" />
      <router-link
        to="settings"
        class="button secondary"
      >
        Einstellungen
      </router-link>
    </section>
    <!--
    <section id="fast-start">
      <p class="font-light">
        Für
      </p>
      <p class="font-bold">
        Schnellstart
      </p>
      <div class="gameFastStart">
        <img
          src="../assets/Pictures/pantomimeBanner.jpg"
          alt=""
          class="gameImg"
        >
        <div>
          <p class="font-normal">
            Drink! or lose
          </p>
          <h3 class="font-bold">
            Drink! or Lose
          </h3>
          <Button
            class="button"
            @click="goToGame('drinkOrLose')"
          >
            Jetzt Spielen!
          </Button>
        </div>
      </div>
    </section>
    <section id="all-games">
      <div class="allGamesDiscription">
        <p class="font-normal">
          Für einen unvergesslichen Abend
        </p>
        <p class="font-bold">
          Alle Spiele im Überblick
        </p>
      </div>

      <swiper
        :effect="'coverflow'"
        :grab-cursor="true"
        :centered-slides="true"
        :slides-per-view="'auto'"
        :loop="true"
        :autoplay="{delay: 5000, disableOnInteraction: false}"
        :coverflow-effect="{
          rotate:0,
          stretch:0,
          depth: 100,
          modifier: 1,
          slideShadows:true,
        }"
        :pagination="true"
        :modules="modules"
        class="gameOverview"
      >
        <swiper-slide
          v-for="game in games"
          :key="game.name"
        >
          <div class="game">
            <img
              :src="'/assets/Pictures/' + game.banner"
              alt=""
              class="gameImg"
            >
            <div>
              <p class="font-normal">
                {{ game.description }}
              </p>
              <h3 class="font-bold">
                {{ game.title }}
              </h3>
              <Button
                class="button"
                @click="$router.push({name: game.goTo})"
              >
                Jetzt spielen!
              </Button>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="centerBtn">
        <button
          id="allGamesBtn"
          @click="goToGame('gameOverview')"
        >
          Alle Spiele
        </button>
      </div>
    </section>
    -->
  </main>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay, Pagination} from "swiper";
import {authStore} from "@/store/authStore";

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {addStore} from "@/store/addStore";

export default {
  name: 'Home',
  title: 'Home',
  components: {
    /*Swiper,
    SwiperSlide,*/
  },
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
  data(){
    return{
      modules: [Autoplay,Pagination],
      games: [
        {
          name: 'spiel1',
          title: 'Scharade',
          image: "./img/tabuBanner.fb0e2d5b.jpg",
          banner: "tabuBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "Scharade"
        },
        {
          name: 'spiel2',
          title: 'Kings Cup',
          image: "./img/kingsCupBanner.d2d0f682.jpg",
          banner: "kingsCupBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "KingsCup"
        },
        {
          name: 'spiel3',
          title: 'Wahrheit oder Pflicht',
          image: "./img/wahrheitOderPflichtBanner.fdadca26.jpg",
          banner: "wahrheitOderPflichtBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "WahrheitOderPflicht"
        },
        {
          name: 'spiel4',
          title: 'Pantomime',
          image: "./img/pantomimeBanner.9f75497f.jpg",
          banner: "pantomimeBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "Pantomime"
        },
        {
          name: 'spiel5',
          title: 'Twink or Daddy',
          image: "./img/twinkOrDaddyBanner.9b404b82.jpg",
          banner: "twinkOrDaddyBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "TwinkOrDaddy"
        },
        {
          name: 'spiel6',
          title: 'Bombig!',
          image: "./img/bombigBanner.c565bd6c.jpg",
          banner: "bombigBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "Bombig"
        },
        {
          name: 'spiel7',
          title: 'Flaschendrehen!',
          image: "./img/flaschenDrehenBanner.b14d0170.jpg",
          banner: "flaschenDrehenBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "FlaschenDrehen"
        },
        {
          name: 'spiel8',
          title: 'Ich habe noch nie!',
          image: "./img/ichHabNochNieBanner.515987b6.jpg",
          banner: "ichHabNochNieBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "IchHabNochNie"
        },
        {
          name: 'spiel9',
          title: 'Wer in diesem Raum',
          image: "./img/werInDiesemRaumBanner.9dee1476.jpg",
          banner: "werInDiesemRaumBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "WerInDiesemRaum"
        },
        {
          name: 'spiel10',
          title: 'Stadt Land Speed',
          image: "./img/stadtLandSpeedBanner.57848458.jpg",
          banner: "stadtLandSpeedBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "StadtLandSpeed"
        },
        {
          name: 'spiel11',
          title: 'Wer bin Ich!',
          image: "./img/werBinIchBanner.d665dd86.jpg",
          banner: "werBinIchBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "WerBinIch"
        },
        {
          name: 'spiel12',
          title: 'Gaytabuu!',
          image: "./img/gayTabuBanner.f1fd8d2b.jpg",
          banner: "gayTabuBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "GayTabu"
        },
        {
          name: 'spiel13',
          title: 'Erotik für Paare!',
          image: "./img/erotikFuerPaareBanner.bbc2423c.jpg",
          banner: "erotikFuerPaareBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "ErotikFuerPaare"
        },
        {
          name: 'spiel14',
          title: 'Entweder Oder!',
          image: "./img/entwederOderBanner.f66baebf.jpg",
          banner: "entwederOderBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "EntwederOder"
        },
        {
          name: 'spiel15',
          title: 'Drink or Lose!',
          image: "./img/entwederOderBanner.f66baebf.jpg",
          banner: "entwederOderBanner.jpg",
          description:"Drink! Or Lose",
          goTo: "DrinkOrLose"
        },
      ]
    }
  },
  methods: {
    goToPlayerSelection(nextgame){
      document.querySelector("#playerSelectionBanner").classList.remove("hidden")
      document.querySelector("#homepageBanner").classList.add("hidden")
      switch(nextgame){
        case "PvP":
          document.getElementById("nextgamePic").setAttribute("src" , "/img/party-pic.9c13ad3e.jpg")
      }

    },
    goToGame(gamename){
      this.$router.push(gamename)

      switch(gamename){
        case "kingsCup":
          if(this.auth.premium){
            break;
          }
          else{
            console.log("Premium erforderlich")
            return;
          }
        case "flaschenDrehen":
          if(this.auth.premium){
            break;
          }
          else{
            console.log("Premium erforderlich")
            return;
          }
        case "gayTabu":
          if(this.auth.premium){
            break;
          }
          else{
            console.log("Premium erforderlich")
            return;
          }
        case "twinkOrDaddy":
          if(this.auth.premium){
            break;
          }
          else{
            console.log("Premium erforderlich")
            return;
          }
        case "erotikFuerPaare":
          if(this.auth.premium){
            break;
          }
          else{
            console.log("Premium erforderlich")
            return;
          }

      }
      document.querySelector("#"+gamename+"Banner").classList.remove("hidden")
      document.querySelector("#homepageBanner").classList.add("hidden")
    },
  }
}
</script>

<style scoped>
header{
  background-image: url('../assets/Pictures/emojis/party-popper.png');
  background-size: 350px;
  background-repeat: no-repeat;
  background-position: center 40px;
  position: relative;
  width: 100%;
  padding-top: 450px;
}
header .cover{
  position: absolute;
  top: 0;
  background-color: rgba(40, 45, 78,0.8);
  width: 100%;
  height: 100%;
}
header .cover img{
  margin-top: 150px;
}
.gameBox{
  max-width: 350px;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
}
.gameBox img{
  opacity: 0.8;
}
.gameBox h6{
 opacity: 0.8;
}



</style>
