import { defineStore } from 'pinia'

const error = new ErrorEvent('doof')

export const addStore = defineStore('add', {
    state: () => ({
        adMobId: process.env.CORDOVA_PLATFORM && process.env.CORDOVA_PLATFORM.toUpperCase() === 'IOS' ?
            {
                //banner: 'ca-app-pub-3940256099942544/2934735716', //test
                //interstitial: 'ca-app-pub-3940256099942544/4411468910', //test
                banner: 'ca-app-pub-9931129804360824/3807893893',
                interstitial: 'ca-app-pub-9931129804360824/4929403870'
            } :
            {
                //banner: 'ca-app-pub-3940256099942544/2934735716', //test
                //interstitial: 'ca-app-pub-3940256099942544/4411468910', //test
                banner: 'ca-app-pub-9931129804360824/7036692462',
                interstitial: 'ca-app-pub-9931129804360824/5532039105'
            },
        counter: 0
    }),
    actions: {
        createBanner(position = AdMob.AD_POSITION.BOTTOM_CENTER){
            return new Promise((resolve, reject) => {
                if(!AdMob) reject('nope')
                AdMob.createBanner({
                    adId: this.adMobId.banner,
                    position: position,
                    autoShow: true
                }, () => {resolve()}, e => reject(e))
            })
        },
        showBanner(position = AdMob.AD_POSITION.BOTTOM_CENTER){
            if(!AdMob) return
            AdMob.showBanner(position)
        },
        removeBanner(){
            if(!AdMob) return
            AdMob.removeBanner()
        },
        hideBanner(){
            if(!AdMob) return
            AdMob.hideBanner()
        },
        initInter(autoShow = false){
            return new Promise((resolve, reject) => {
                if(!AdMob) reject('nope')
                AdMob.prepareInterstitial( {
                    adId:this.adMobId.interstitial,
                    autoShow:autoShow
                }, () => {
                    AdMob.isInterstitialReady(ready => {
                        if(ready){
                            resolve()
                        }else{
                            reject()
                        }
                    })
                }, e => reject(e))
            })
        },
        isInterstitialReady() {
            return new Promise((resolve, reject) => {
                AdMob.isInterstitialReady(ready => {
                    if(ready){
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        },
        showInter(force = false){
            if(!AdMob) return
            const _this = this
            if(force || this.counter >= 10){
                this.isInterstitialReady()
                    .then(r => {
                        AdMob.showInterstitial()
                        _this.initInter()
                        _this.counter = 0
                    })
                    .catch(e => {console.log(e)})
            }else{
                this.counter++
            }
        },
    }
})