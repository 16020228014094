<template>
  <div>
    <main
      class="theme-drinkorlose"
      v-if="page == 'default'"
    >
      <div class="pad-8">
        <router-link to="/home">
          <img
            src="../assets/Icons/back.svg"
            class="width-32"
            style="filter: invert(1)"
          >
        </router-link>
      </div>
      <div class="cover t-center">
        <img
          src="assets/Pictures/logo.png"
          class="width-350"
        >
      </div>
      <div class="pad-32" />
      <div class="color-white pad-16">
        <h6 style="font-weight: normal;">
          Hey Boy
        </h6>
        <h3>Was machen Sachen?</h3>
      </div>
      <div class="pad-16" />
      <section class="t-center">
        <a
          @click="page = 'addquestion'"
          class="button large primary"
          style="padding: 8px 48px; width: 100%; max-width: 300px;"
        >
          Frage hinzufügen
        </a>
        <div class="pad-16" />
        <a
          @click="page = 'checkquestion'"
          class="button large primary"
          style="padding: 8px 48px; width: 100%; max-width: 300px;"
        >
          Frage prüfen
        </a>
        <div class="pad-16" />
      </section>
    </main>
    <main
      class="theme-drinkorlose"
      v-if="page == 'addquestion'"
    >
      <header>
        <div class="pad-8">
          <a @click="page = 'default'">
            <img
              src="../assets/Icons/back.svg"
              class="width-32"
              style="filter: invert(1)"
            >
          </a>
        </div>
        <div class="cover t-center">
          <img
            src="assets/Pictures/logo.png"
            class="width-350"
          >
        </div>
      </header>
      <section class="pad-16">
        <div class="pad-16" />
        <div class="color-white pad-16">
          <h6 style="font-weight: normal;">
            Admin Tool
          </h6>
          <h3>Frage oder Aufgabe hinzufügen</h3>
        </div>
        <form method="post">
          <div class="field pad-16">
            <label class="color-white">Text</label>
            <textarea
              rows="3"
              name="text"
              placeholder="Text"
              style="display: block; width: 100%; max-width: calc(100% - 16px); border-radius: 10px; padding: 8px;"
              required
            />
          </div>
          <div class="field pad-16">
            <label class="color-white">Auswahl Spiel</label>
            <select
              name="spiel"
              style="display: block; width: 100%; max-width: 100%; border-radius: 10px; padding: 8px;"
            >
              <option selected>
                Bitte auswählen
              </option>
              <option value="Bombig">
                Bombig
              </option>
              <option value="Flaschendrehen">
                Flaschendrehen
              </option>
              <option value="Wahrheit oder Pflicht">
                Wahrheit oder Pflicht
              </option>
              <option value="Ich habe noch nie!">
                Ich habe noch nie!
              </option>
              <option value="Entweder Oder!">
                Entweder Oder!
              </option>
              <option value="Wer in diesem Raum">
                Wer in diesem Raum
              </option>
              <option value="Pantomime">
                Pantomime
              </option>
              <option value="Scharade">
                Scharade
              </option>
              <option value="Wer bin Ich?">
                Wer bin Ich?
              </option>
              <option value="Stadt Land Speed">
                Stadt Land Speed
              </option>
              <option value="Erotik für Paare!">
                Erotik für Paare!
              </option>
              <option value="Kings Cup">
                Kings Cup
              </option>
              <option value="Twink or Daddy">
                Twink or Daddy
              </option>
              <option value="Pride Scharade">
                Pride Scharade
              </option>
            </select>
          </div>
          <div class="field pad-16">
            <label class="color-white">Auswahl Spielstufe (falls vorhanden)</label>
            <select
              name="spielstufe"
              style="display: block; width: 100%; max-width: 100%; border-radius: 10px; padding: 8px;"
            >
              <option selected>
                Bitte auswählen
              </option>
              <option value="Spaß">
                Spaß
              </option>
              <option value="Dirty">
                Dirty
              </option>
              <option value="Warm Up">
                Warm Up
              </option>
              <option value="Erotisch">
                Erotisch
              </option>
              <option value="Sex Beichten">
                Sex Beichten
              </option>
              <option value="Love Game">
                Love Game
              </option>
              <option value="Schimpfscharade">
                Schimpfscharade
              </option>
              <option value="Dirty Scharade">
                Dirty Scharade
              </option>
              <option value="All in One">
                All in One
              </option>
              <option value="Wer bin Ich?">
                Wer bin Ich?
              </option>
            </select>
          </div>
          <div class="pad-16 t-center">
            <button
              type="submit"
              class="button large primary"
              style="padding: 8px 48px; width: 100%; max-width: 300px;"
            >
              Speichern
            </button>
          </div>
        </form>
      </section>
    </main>
    <main
      class="theme-drinkorlose"
      v-if="page == 'checkquestion'"
    >
      <header>
        <div class="pad-8">
          <a @click="page = 'default'">
            <img
              src="../assets/Icons/back.svg"
              class="width-32"
              style="filter: invert(1)"
            >
          </a>
        </div>
        <div class="cover t-center">
          <img
            src="assets/Pictures/logo.png"
            class="width-350"
          >
        </div>
      </header>
      <section class="pad-16">
        <div class="pad-16" />
        <div class="color-white pad-16">
          <h6 style="font-weight: normal;">
            Admin Tool
          </h6>
          <h3>Fragen prüfen</h3>
        </div>
        <form method="post">
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12 pad-8">
              <button
                type="button"
                class="button large secondary"
                style="padding: 8px 48px; width: 100%;"
              >
                Ablehnen
              </button>
            </div>
            <div class="col-sp-6 row-sp-12 pad-8">
              <button
                type="submit"
                class="button large primary"
                style="padding: 8px 48px; width: 100%;"
              >
                Speichern
              </button>
            </div>
          </div>
        </form>
      </section>
    </main>
  </div>
</template>
<script>
import {authStore} from "@/store/authStore";

export default {
  name: "Admintool",
  title: "Admintool",
  setup() {
    const auth = authStore()

    return {auth}
  },
  data(){
    return{
      page : 'default',
    }
  },
}
</script>

<style scoped>

</style>