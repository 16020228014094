<template>
  <img
    src="/assets/Icons/back.svg"
    alt=""
    class="back-arrow"
    @click="$router.push('/')"
  >
</template>

<script>
export default {
  name: "Game"
}
</script>

<style scoped>
.back-arrow{
  filter: invert(1);
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>