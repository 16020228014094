<template>
  <header class="GameHeader grid-12">
    <div class="col-sp-6 row-sp-12 t-left">
      <img
        src="/assets/Icons/help.svg"
        alt=""
        class="helpsymbol"
        @click="helpmenu()"
      >
      <div
        id="flaschendrehenRules"
        class="Rules hidden"
      >
        <div class="closeBtn">
          <img
            src="../assets/Icons/close.svg"
            alt=""
            class="redBtn"
            @click="helpmenu()"
          >
        </div>
        <h4 class="font-bold">
          Flaschendrehen Spielregeln
        </h4><br>
        <span class="font-normal">Das Spiel beginnt und die Flasche dreht sich. Der Spieler,
          bei dem die Flasche drauf zeigt, muss die Aufgabe lösen oder die Frage beantworten.
          Bei Nichterfüllung muss der Spieler 2 Schlücke trinken.
          Danach ist der nächste Spieler an der Reihe.
        </span><br><br>
      </div>
    </div>
    <div class="col-sp-6 row-sp-12 t-right">
      <router-link
        to="gameOverview"
        class="backCloseButton"
      >
        <img
          src="/assets/Icons/close.svg"
        >
      </router-link>
    </div>
  </header>
  <section class="container">
    <h1 class="font-bold">
      Flaschendrehen
    </h1>
    <!-- Bottle Image -->
    <div
      class="img-container"
      id="imgConti"
    >
      <img
        src="../assets/Icons/flasche.svg"
        id="bottle"
        alt=""
      >
    </div>
    <!-- Spin Button -->
    <div
      class="btn-container"
      id="btnConti"
    />
    <!-- Pop Up -hidden -->
    <div
      class="message-container hidden"
      id="pop-up"
    >
      <p id="challange">
        Hier wird die Nachricht von der Datenbank eingefügt (JSON Datei)
      </p>
    </div>
    <div class="buttons">
      <button
        class="btn"
        id="spinBtn"
        @click="startAnimation()"
      >
        Drehen
      </button>
      <button
        class="weiter-btn hidden"
        id="weiterBtn"
        @click="weiterBtn()"
      >
        Weiter
      </button> 
    </div>   
  </section>
</template>

<script>
import { gsap } from 'gsap';
import json from '@/jsonDaten/flaschendrehen.json'

export default {
  name: "FlaschenDrehen",
  title: "Flaschen drehen",
    data(){
        return {
            FlaschenDrehenDaten : json
        }
    },
    methods: {
        helpmenu : function(){
            document.querySelector("#flaschendrehenRules").classList.toggle("hidden");
        },
        startAnimation : function(){
            let deg = 0;
            const twistTL = new gsap.timeline()
            document.getElementById("spinBtn").classList.add("hidden");
            deg = Math.floor(5000 + Math.random() * 5000);
            twistTL.to("#bottle", 4, {rotate : deg, ease: "power3.out"})
            setTimeout(this.displayChallange, 7000);
        },
        displayChallange : function(){
            document.getElementById("bottle").classList.add("lowOpacity");
            document.getElementById("pop-up").classList.remove("hidden");
            document.getElementById("weiterBtn").classList.remove("hidden");
            this.renderFDDaten(this.FlaschenDrehenDaten.Fragen_und_Aufgaben);
        },
        weiterBtn : function(){
            document.getElementById("pop-up").classList.add("hidden");
            document.getElementById("spinBtn").classList.remove("hidden");
            document.getElementById("bottle").classList.remove("lowOpacity");
            document.getElementById("weiterBtn").classList.add("hidden");
        },
        renderFDDaten : function(jsonDaten){
            console.log("text")
            const randomIndex = Math.floor(Math.random() * jsonDaten.length);
            if(jsonDaten.length <= 0){
                    document.querySelector("flaschenDrehenBanner").classList.add("hidden")
                    document.querySelector("endcardBanner").classList.remove("hidden")
                }
            jsonDaten.splice(randomIndex, 1)
            const item = jsonDaten[randomIndex];
            document.getElementById("challange").innerHTML = item
        }
    }
}
</script>

<style scoped>
    .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}
.container h1{
    color: white;
    font-size: 30px;
    display: flex;
    align-items: center;
    margin: 20px;
    margin-bottom: 15vh;
}
#bottle {
    width: 70px;
    position: relative;
    left: calc(50% - 35px);
    top: 50%;
    z-index: 3;
}
.buttons{
    position: absolute;
    bottom: 40px;
}
.img-container {
    position: relative;
    display: flex;
    align-items: center;
}
.btn-container {
    position: relative;
    display: flex;
    align-items: center;
}
.lowOpacity {
    opacity: 0.3;
}
.btn {
    background-color: #545C8F;
    font-size: 20px;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 140px;
}
.message-container {
    position: relative;
    border-radius: 5px;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: clamp(30px, 40px, 50px);
    font-weight: bold;
    text-align: center;
    user-select: none;
}
.weiter-btn{
    background-color: #FFA301;
    font-size: 20px;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 140px;
    position: relative;
}
</style>