<template>
  <div
    id="werBinIchRules"
    class="Rules hidden"
  >
    <div class="closeBtn">
      <img
        src="../assets/Icons/close.svg"
        alt=""
        class="redBtn"
        @click="helpmenu()"
      >
    </div>
    <h4 class="font-bold">
      Wer bin Ich Spielregeln
    </h4><br>
    <span class="font-normal">Alle Spieler downloaden sich diese App und halten das Handy an die Stirn. Ein gegnerischer Spieler öffnet das Spiel und entscheidet durchs Tappen eine prominente Figur, die du erraten musst. Jetzt darf jeder der Reihe nach eine Frage stellen, die der Rest der Gruppe mit Ja oder Nein beantwortet. Wird deine Frage mit Ja beantwortet, bist du erneut dran. Bei Nein, ist der nächste Spieler an der Reihe. Als erste Frage eignet sich beispielsweise: „Bin ich eine Frau?".
    </span><br><br>
  </div>
  <header class="GameHeader grid-12">
    <div class="col-sp-6 row-sp-12 t-left">
      <img
        src="/assets/Icons/help.svg"
        alt=""
        class="helpsymbol"
        @click="helpmenu()"
      >
    </div>
    <div class="col-sp-6 row-sp-12 t-right">
      <router-link
        to="gameOverview"
        class="backCloseButton"
      >
        <img
          src="/assets/Icons/close.svg"
        >
      </router-link>
    </div>
  </header>
  <section id="werBinIchGame">
    <div
      id="wBICategorieSelection"
      class=""
    >
      <h2>Wer bin ich?</h2>
      <div class="wBICategorie">
        <div class="musicChoose">
          <div
            class="musicMale"
            @click="selectCategorie('musicMale')"
          >
            <div class="selected hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
              >
                <g
                  id="Gruppe_36"
                  data-name="Gruppe 36"
                  transform="translate(-165.156 -194.156)"
                >
                  <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="11"
                    cy="11"
                    r="11"
                    transform="translate(165.156 194.156)"
                    fill="#ffa301"
                  />
                  <g
                    id="icon_action_check_circle_24px_"
                    data-name="icon/action/check_circle_24px "
                    transform="translate(167.156 196.156)"
                  >
                    <rect
                      id="Boundary"
                      width="19"
                      height="19"
                      fill="none"
                    />
                    <path
                      id="_Color"
                      data-name=" ↳Color"
                      d="M4.338,8.089,1.085,4.884,0,5.952l4.338,4.273,9.3-9.157L12.55,0Z"
                      transform="translate(2.556 4.261)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="56"
              height="56"
              viewBox="0 0 56 56"
            >
              <defs>
                <clipPath id="clip-path">
                  <path
                    id="Pfad_41"
                    data-name="Pfad 41"
                    d="M49.055,275.8h37v38.5h-37Zm0,0"
                    transform="translate(-49.055 -275.801)"
                  />
                </clipPath>
              </defs>
              <g
                id="Gruppe_34"
                data-name="Gruppe 34"
                transform="translate(10 8.751)"
              >
                <path
                  id="Pfad_45"
                  data-name="Pfad 45"
                  d="M22,0H34A22,22,0,0,1,56,22V34A22,22,0,0,1,34,56H22A22,22,0,0,1,0,34V22A22,22,0,0,1,22,0Z"
                  transform="translate(-10 -8.751)"
                  fill="#fff"
                />
                <path
                  id="Pfad_39"
                  data-name="Pfad 39"
                  d="M149.9,409.785a5.364,5.364,0,0,0-3.818,1.581h0a5.4,5.4,0,1,0,3.819-1.581"
                  transform="translate(-133.6 -393.609)"
                  fill="#53ade5"
                />
                <g
                  id="Gruppe_32"
                  data-name="Gruppe 32"
                  transform="translate(-0.5 0)"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Pfad_40"
                    data-name="Pfad 40"
                    d="M77.323,286.023h0v5.742a1.1,1.1,0,1,1-2.19,0v-2.991l-3.693,3.842a7.951,7.951,0,0,1,.927,1.7,8.189,8.189,0,0,1,0,6.045,7.869,7.869,0,0,1-1.645,2.563,7.533,7.533,0,0,1-2.463,1.712,7.318,7.318,0,0,1-5.809,0,7.538,7.538,0,0,1-2.463-1.712,7.893,7.893,0,0,1-1.646-2.563,8.195,8.195,0,0,1,0-6.045,7.89,7.89,0,0,1,1.646-2.562,7.533,7.533,0,0,1,2.462-1.712,7.3,7.3,0,0,1,2.9-.6h0a7.29,7.29,0,0,1,2.9.6,7.476,7.476,0,0,1,1.633.964l3.693-3.843H70.71a1.14,1.14,0,0,1,0-2.279h5.521a1.072,1.072,0,0,1,.765.327h.012a1.162,1.162,0,0,1,.314.8Zm3.313-4.584a18,18,0,0,0-26.163,0,19.805,19.805,0,0,0,0,27.223,18,18,0,0,0,26.163,0,19.806,19.806,0,0,0,0-27.223"
                    transform="translate(-49.055 -275.801)"
                    fill="#53ade5"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div
            class="musicFemale"
            @click="selectCategorie('musicFemale')"
          >
            <div class="selected">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
              >
                <g
                  id="Gruppe_36"
                  data-name="Gruppe 36"
                  transform="translate(-165.156 -194.156)"
                >
                  <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="11"
                    cy="11"
                    r="11"
                    transform="translate(165.156 194.156)"
                    fill="#ffa301"
                  />
                  <g
                    id="icon_action_check_circle_24px_"
                    data-name="icon/action/check_circle_24px "
                    transform="translate(167.156 196.156)"
                  >
                    <rect
                      id="Boundary"
                      width="19"
                      height="19"
                      fill="none"
                    />
                    <path
                      id="_Color"
                      data-name=" ↳Color"
                      d="M4.338,8.089,1.085,4.884,0,5.952l4.338,4.273,9.3-9.157L12.55,0Z"
                      transform="translate(2.556 4.261)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="56"
              height="56"
              viewBox="0 0 56 56"
            >
              <defs>
                <clipPath id="clip-path">
                  <path
                    id="Pfad_44"
                    data-name="Pfad 44"
                    d="M405,275.8h37v38.5H405Zm0,0"
                    transform="translate(-405 -275.801)"
                  />
                </clipPath>
              </defs>
              <g
                id="Gruppe_35"
                data-name="Gruppe 35"
                transform="translate(10.496 8.751)"
              >
                <path
                  id="Pfad_46"
                  data-name="Pfad 46"
                  d="M22,0H34A22,22,0,0,1,56,22V34A22,22,0,0,1,34,56H22A22,22,0,0,1,0,34V22A22,22,0,0,1,22,0Z"
                  transform="translate(-10.496 -8.751)"
                  fill="#fff"
                />
                <path
                  id="Pfad_42"
                  data-name="Pfad 42"
                  d="M525.115,354.281a5.4,5.4,0,1,0,5.4,5.4,5.364,5.364,0,0,0-5.4-5.4"
                  transform="translate(-507.615 -344.806)"
                  fill="#f36bbc"
                />
                <g
                  id="Gruppe_33"
                  data-name="Gruppe 33"
                  transform="translate(-0.996 0)"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Pfad_43"
                    data-name="Pfad 43"
                    d="M428.867,296.329a7.43,7.43,0,0,1-4.272,2.233v3.431h2.844a1.141,1.141,0,0,1,0,2.279H424.6V306.1a1.1,1.1,0,1,1-2.19,0v-1.826h-2.844a1.141,1.141,0,0,1,0-2.279H422.4v-3.431a7.429,7.429,0,0,1-4.272-2.233,8.009,8.009,0,0,1-2.223-5.584,8.132,8.132,0,0,1,.578-3.023,7.887,7.887,0,0,1,1.645-2.562,7.539,7.539,0,0,1,2.462-1.712,7.305,7.305,0,0,1,2.9-.6,7.4,7.4,0,0,1,5.367,2.313,8.125,8.125,0,0,1,0,11.169Zm7.714-14.89a18,18,0,0,0-26.163,0,19.805,19.805,0,0,0,0,27.223,17.995,17.995,0,0,0,26.163,0,19.805,19.805,0,0,0,0-27.223"
                    transform="translate(-405 -275.801)"
                    fill="#f36bbc"
                  />
                </g>
              </g>
            </svg>                        
          </div>
        </div>
        <h3>Musiker*innen</h3>
      </div>
      <div class="wBICategorie">
        <div class="actorChoose">
          <div
            class="actorMale"
            @click="selectCategorie('actorMale')"
          >
            <div class="selected hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
              >
                <g
                  id="Gruppe_36"
                  data-name="Gruppe 36"
                  transform="translate(-165.156 -194.156)"
                >
                  <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="11"
                    cy="11"
                    r="11"
                    transform="translate(165.156 194.156)"
                    fill="#ffa301"
                  />
                  <g
                    id="icon_action_check_circle_24px_"
                    data-name="icon/action/check_circle_24px "
                    transform="translate(167.156 196.156)"
                  >
                    <rect
                      id="Boundary"
                      width="19"
                      height="19"
                      fill="none"
                    />
                    <path
                      id="_Color"
                      data-name=" ↳Color"
                      d="M4.338,8.089,1.085,4.884,0,5.952l4.338,4.273,9.3-9.157L12.55,0Z"
                      transform="translate(2.556 4.261)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="56"
              height="56"
              viewBox="0 0 56 56"
            >
              <defs>
                <clipPath id="clip-path">
                  <path
                    id="Pfad_41"
                    data-name="Pfad 41"
                    d="M49.055,275.8h37v38.5h-37Zm0,0"
                    transform="translate(-49.055 -275.801)"
                  />
                </clipPath>
              </defs>
              <g
                id="Gruppe_34"
                data-name="Gruppe 34"
                transform="translate(10 8.751)"
              >
                <path
                  id="Pfad_45"
                  data-name="Pfad 45"
                  d="M22,0H34A22,22,0,0,1,56,22V34A22,22,0,0,1,34,56H22A22,22,0,0,1,0,34V22A22,22,0,0,1,22,0Z"
                  transform="translate(-10 -8.751)"
                  fill="#fff"
                />
                <path
                  id="Pfad_39"
                  data-name="Pfad 39"
                  d="M149.9,409.785a5.364,5.364,0,0,0-3.818,1.581h0a5.4,5.4,0,1,0,3.819-1.581"
                  transform="translate(-133.6 -393.609)"
                  fill="#53ade5"
                />
                <g
                  id="Gruppe_32"
                  data-name="Gruppe 32"
                  transform="translate(-0.5 0)"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Pfad_40"
                    data-name="Pfad 40"
                    d="M77.323,286.023h0v5.742a1.1,1.1,0,1,1-2.19,0v-2.991l-3.693,3.842a7.951,7.951,0,0,1,.927,1.7,8.189,8.189,0,0,1,0,6.045,7.869,7.869,0,0,1-1.645,2.563,7.533,7.533,0,0,1-2.463,1.712,7.318,7.318,0,0,1-5.809,0,7.538,7.538,0,0,1-2.463-1.712,7.893,7.893,0,0,1-1.646-2.563,8.195,8.195,0,0,1,0-6.045,7.89,7.89,0,0,1,1.646-2.562,7.533,7.533,0,0,1,2.462-1.712,7.3,7.3,0,0,1,2.9-.6h0a7.29,7.29,0,0,1,2.9.6,7.476,7.476,0,0,1,1.633.964l3.693-3.843H70.71a1.14,1.14,0,0,1,0-2.279h5.521a1.072,1.072,0,0,1,.765.327h.012a1.162,1.162,0,0,1,.314.8Zm3.313-4.584a18,18,0,0,0-26.163,0,19.805,19.805,0,0,0,0,27.223,18,18,0,0,0,26.163,0,19.806,19.806,0,0,0,0-27.223"
                    transform="translate(-49.055 -275.801)"
                    fill="#53ade5"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div
            class="actorFemale"
            @click="selectCategorie('actorFemale')"
          >
            <div class="selected hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
              >
                <g
                  id="Gruppe_36"
                  data-name="Gruppe 36"
                  transform="translate(-165.156 -194.156)"
                >
                  <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="11"
                    cy="11"
                    r="11"
                    transform="translate(165.156 194.156)"
                    fill="#ffa301"
                  />
                  <g
                    id="icon_action_check_circle_24px_"
                    data-name="icon/action/check_circle_24px "
                    transform="translate(167.156 196.156)"
                  >
                    <rect
                      id="Boundary"
                      width="19"
                      height="19"
                      fill="none"
                    />
                    <path
                      id="_Color"
                      data-name=" ↳Color"
                      d="M4.338,8.089,1.085,4.884,0,5.952l4.338,4.273,9.3-9.157L12.55,0Z"
                      transform="translate(2.556 4.261)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="56"
              height="56"
              viewBox="0 0 56 56"
            >
              <defs>
                <clipPath id="clip-path">
                  <path
                    id="Pfad_44"
                    data-name="Pfad 44"
                    d="M405,275.8h37v38.5H405Zm0,0"
                    transform="translate(-405 -275.801)"
                  />
                </clipPath>
              </defs>
              <g
                id="Gruppe_35"
                data-name="Gruppe 35"
                transform="translate(10.496 8.751)"
              >
                <path
                  id="Pfad_46"
                  data-name="Pfad 46"
                  d="M22,0H34A22,22,0,0,1,56,22V34A22,22,0,0,1,34,56H22A22,22,0,0,1,0,34V22A22,22,0,0,1,22,0Z"
                  transform="translate(-10.496 -8.751)"
                  fill="#fff"
                />
                <path
                  id="Pfad_42"
                  data-name="Pfad 42"
                  d="M525.115,354.281a5.4,5.4,0,1,0,5.4,5.4,5.364,5.364,0,0,0-5.4-5.4"
                  transform="translate(-507.615 -344.806)"
                  fill="#f36bbc"
                />
                <g
                  id="Gruppe_33"
                  data-name="Gruppe 33"
                  transform="translate(-0.996 0)"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Pfad_43"
                    data-name="Pfad 43"
                    d="M428.867,296.329a7.43,7.43,0,0,1-4.272,2.233v3.431h2.844a1.141,1.141,0,0,1,0,2.279H424.6V306.1a1.1,1.1,0,1,1-2.19,0v-1.826h-2.844a1.141,1.141,0,0,1,0-2.279H422.4v-3.431a7.429,7.429,0,0,1-4.272-2.233,8.009,8.009,0,0,1-2.223-5.584,8.132,8.132,0,0,1,.578-3.023,7.887,7.887,0,0,1,1.645-2.562,7.539,7.539,0,0,1,2.462-1.712,7.305,7.305,0,0,1,2.9-.6,7.4,7.4,0,0,1,5.367,2.313,8.125,8.125,0,0,1,0,11.169Zm7.714-14.89a18,18,0,0,0-26.163,0,19.805,19.805,0,0,0,0,27.223,17.995,17.995,0,0,0,26.163,0,19.805,19.805,0,0,0,0-27.223"
                    transform="translate(-405 -275.801)"
                    fill="#f36bbc"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <h3>Schauspieler*innen</h3>
      </div>
      <div class="wBICategorie">
        <div
          class="bandChoose"
          @click="selectCategorie('bands')"
        >
          <div class="selected hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
            >
              <g
                id="Gruppe_36"
                data-name="Gruppe 36"
                transform="translate(-165.156 -194.156)"
              >
                <circle
                  id="Ellipse_4"
                  data-name="Ellipse 4"
                  cx="11"
                  cy="11"
                  r="11"
                  transform="translate(165.156 194.156)"
                  fill="#ffa301"
                />
                <g
                  id="icon_action_check_circle_24px_"
                  data-name="icon/action/check_circle_24px "
                  transform="translate(167.156 196.156)"
                >
                  <rect
                    id="Boundary"
                    width="19"
                    height="19"
                    fill="none"
                  />
                  <path
                    id="_Color"
                    data-name=" ↳Color"
                    d="M4.338,8.089,1.085,4.884,0,5.952l4.338,4.273,9.3-9.157L12.55,0Z"
                    transform="translate(2.556 4.261)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <h3>Bands</h3>
      </div>
      <div class="wBICategorie">
        <div
          class="germanStarChoose"
          @click="selectCategorie('germanStars')"
        >
          <div class="selected hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
            >
              <g
                id="Gruppe_36"
                data-name="Gruppe 36"
                transform="translate(-165.156 -194.156)"
              >
                <circle
                  id="Ellipse_4"
                  data-name="Ellipse 4"
                  cx="11"
                  cy="11"
                  r="11"
                  transform="translate(165.156 194.156)"
                  fill="#ffa301"
                />
                <g
                  id="icon_action_check_circle_24px_"
                  data-name="icon/action/check_circle_24px "
                  transform="translate(167.156 196.156)"
                >
                  <rect
                    id="Boundary"
                    width="19"
                    height="19"
                    fill="none"
                  />
                  <path
                    id="_Color"
                    data-name=" ↳Color"
                    d="M4.338,8.089,1.085,4.884,0,5.952l4.338,4.273,9.3-9.157L12.55,0Z"
                    transform="translate(2.556 4.261)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <h3>Deutsche Stars</h3>
      </div>
      <div class="btn">
        <button
          id="selectChooseBtn"
          @click="pushSelectCategories()"
        >
          Weiter
        </button>
        <!-- <button id="gameRulesBtn">Spieleanleitung</button> -->
      </div>
    </div>
    <div
      id="wBIGame"
      class="hidden"
    >
      <h1
        style="position: absolute; width: 100%;"
        class="font-bolder color-white t-center pad-top-16"
      >
        Wer bin ich?
      </h1>
      <div class="container">
        <h3 id="wBITask">
          Drücke Start um zu beginnen
        </h3>
        <div class="gameMenu">
          <button
            id="startwBIGame"
            @click="startwBIGame()"
          >
            Start
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import json from '@/jsonDaten/werBinIch.json'
import {authStore} from "@/store/authStore";
import {addStore} from "@/store/addStore";
export default {
  name : "WerBinIch",
  title : "Wer bin ich?",
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
     data(){
        return{
            wBIjson : json,
            selectedCategories: [],
            gameArray: []
        }
    },
    methods:{
        selectCategorie : function(categorie){
            // Hier noch abfrage ob Premium sonst werbefenster öffnen um Premium zu erwerben
            switch(categorie){
                case "musicMale":
                    if(this.auth.premium){
                        document.querySelector(".musicMale").firstChild.classList.toggle("hidden");
                        return;
                    }
                    else{
                        console.log("du brauchst Premium")
                    }
                    break;
                case "musicFemale":
                    document.querySelector(".musicFemale").firstChild.classList.toggle("hidden");
                    break;
                case "actorMale":
                    if(this.auth.premium){
                        document.querySelector(".actorMale").firstChild.classList.toggle("hidden");
                        return;
                    }
                    else{
                        console.log("du brauchst Premium")
                    }
                    break;
                case "actorFemale":
                    if(this.auth.premium){
                        document.querySelector(".actorFemale").firstChild.classList.toggle("hidden");
                        return;
                    }
                    else{
                        console.log("du brauchst Premium")
                    }
                    break;
                case "bands":
                    if(this.auth.premium){
                        document.querySelector(".bandChoose").firstChild.classList.toggle("hidden");
                        return;
                    }
                    else{
                        console.log("du brauchst Premium")
                    }
                    break;
                case "germanStars":
                    if(this.auth.premium){
                        document.querySelector(".germanStarChoose").firstChild.classList.toggle("hidden");
                        return;
                    }
                    else{
                        console.log("du brauchst Premium")
                    }
                    break;
            }
        },
        pushSelectCategories : function(){
            this.selectedCategories
            if(!document.querySelector(".musicMale").firstChild.classList.contains("hidden")){
                this.selectedCategories.push("musicMale")
            }
            if(!document.querySelector(".musicFemale").firstChild.classList.contains("hidden")){
                this.selectedCategories.push("musicFemale")
            }
            if(!document.querySelector(".actorMale").firstChild.classList.contains("hidden")){
                this.selectedCategories.push("actorMale")
            }
            if(!document.querySelector(".actorFemale").firstChild.classList.contains("hidden")){
                this.selectedCategories.push("actorFemale")
            }
            if(!document.querySelector(".bandChoose").firstChild.classList.contains("hidden")){
                this.selectedCategories.push("bands")
            }
            if(!document.querySelector(".germanStarChoose").firstChild.classList.contains("hidden")){
                this.selectedCategories.push("germanStars")
            }
            
            this.selectedCategories.forEach(categorie => {
                const jsonArray = []
                jsonArray.push([categorie,this.wBIjson [categorie]])
                jsonArray[0][1].forEach(name =>{
                    this.gameArray.push(name)
                })
            });
            console.log(this.gameArray)
            if(this.gameArray.length === 0){
                return
            }
            document.querySelector("#wBICategorieSelection").classList.add("hidden")
            document.querySelector("#wBIGame").classList.remove("hidden")
            return this.gameArray
        },
        startwBIGame : function(){
            if(this.gameArray.length === 0){
                document.querySelector("#werBinIchBanner").classList.add("hidden")
                document.querySelector("#endcardBanner").classList.remove("hidden")
            }
            const randomNumber = [Math.floor(Math.random()*this.gameArray.length)]
            let question = this.gameArray[randomNumber]
            this.gameArray.splice(randomNumber, 1)
            document.querySelector("#wBITask").innerHTML = question
            document.querySelector("#startwBIGame").innerHTML = "Weiter"
        },
        helpmenu : function(){
            document.querySelector("#werBinIchRules").classList.toggle("hidden");
        },
    }    
}
</script>

<style scoped>
#wBICategorieSelection{
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wBICategorie{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}
.selected {
 position: absolute;
 right: -5px;
 bottom: -5px;
}
h3 , h2{
    font-family: 'open sans';
    color: white;
    font-size: 24px;
    font-weight: bold;
}
.musicChoose , .actorChoose{
    display: flex;
    justify-content: center;
    gap: 20px;
}
.bandChoose , .germanStarChoose{
    height: 56px;
    width: 56px;
    border-radius: 22px;
    background-color: white;
    margin: 10px 0px;
    position: relative;
}
.musicMale , .musicFemale, .actorMale , .actorFemale{
    position: relative;
}
.btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-bottom: 40px;
}
#selectChooseBtn {
    width: 130px;
    height: 35px;
    color: white;
    background-color: #FFA301;
    border-radius: 7px;
    border-style: none;
    font-size: 16px;
    font-family: 'open sans';
    font-weight: bold;
}
#gameRulesBtn {
    width: 165px;
    height: 35px;
    color: white;
    background-color: #545C8F;
    border-radius: 7px;
    border-style: none;
    font-size: 16px;
    font-family: 'open sans';
    font-weight: bold;
}
#startwBIGame{
    width: 130px;
    height: 35px;
    color: white;
    background-color: #FFA301;
    border-radius: 7px;
    border-style: none;
    font-size: 16px;
    font-family: 'open sans';
    font-weight: bold;
}
#wBIGame{

}
.container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
  text-align: center;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}
</style>