<template>
  <div
    id="stadtLandSpeedRules"
    class="Rules hidden"
  >
    <div class="closeBtn">
      <img
        src="/assets/Icons/close.svg"
        alt=""
        class="redBtn"
        @click="helpmenu()"
      >
    </div>
    <h4 class="font-bold">
      Stadt Land Speed Spielregeln
    </h4><br>
    <span class="font-normal">Das Spiel startet. Du siehst alle 3 Kategorien und einen ablaufenden Timer in Sekundenschnelle. Der Buchstabe wird beim Start zufällig ausgewählt. Schaffst du es, alle 3 Kategorien richtig in der Zeit zu beantworten, hast du die Runde gewonnen. Falls nicht, musst du einen Schluck trinken. Danach ist der nächste Spieler an der Reihe.
    </span><br><br>
  </div>
  <header class="GameHeader grid-12">
    <div class="col-sp-6 row-sp-12 t-left">
      <img
        src="/assets/Icons/help.svg"
        alt=""
        class="helpsymbol"
        @click="helpmenu()"
      >
    </div>
    <div class="col-sp-6 row-sp-12 t-right">
      <router-link
        to="gameOverview"
        class="backCloseButton"
      >
        <img
          src="/assets/Icons/close.svg"
        >
      </router-link>
    </div>
  </header>
  <section id="stadtLandSpeedGame">
    <div class="gameArea">
      <h1
        style="position: absolute; width: 100%;"
        class="font-bolder color-white t-center pad-top-16"
      >
        Stadt Land Speed
      </h1>
      <div class="container">
        <div class="categories">
          <h3 class="font-bold">
            Stadt
          </h3>
          <h3 class="font-bold">
            Land
          </h3>
          <h3
            id="taskCategorie"
            class="font-bold"
          >
            ?
          </h3>
        </div>
        <h2
          id="taskLetter"
          class="hidden"
        />
        <div class="gameMenu">
          <div class="centerTimer">
            <h2 id="backgroundTimer" />
          </div>
          <button
            class="sLSstartBtn"
            @click="startGame()"
          >
            Start
          </button>
          <button
            class="sLSnextBtn hidden"
            @click="nextStep()"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import json from '@/jsonDaten/StadtLandSpeed.json'
import {addStore} from "@/store/addStore";
import {authStore} from "@/store/authStore";
export default {
  name : "StadtLandSpeed",
  title : "Stadt Land Speed",
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
    data(){
        return{
            jsonDaten : json,
            timerInterval: null,
            time: 12
        }
    },
    methods:{
        backToHome : function(){
            document.querySelector("#homepageBanner").classList.remove("hidden")
            document.querySelector("#werInDiesemRaumBanner").classList.add("hidden")
            location.reload();
        },
        startGame : function(){
            document.querySelector(".sLSstartBtn").classList.add("hidden");
            document.querySelector(".sLSnextBtn").classList.remove("hidden");
            document.querySelector("#taskLetter").classList.remove("hidden");
            this.randomCategorie(this.jsonDaten);
            this.randomLetter(this.jsonDaten);
            this.startTimer()
        },
        helpmenu : function(){
            document.querySelector("#stadtLandSpeedRules").classList.toggle("hidden");
        },
        nextStep : function(){

            this.stopTimer()
            this.startTimer()
            this.randomCategorie(this.jsonDaten);
            this.randomLetter(this.jsonDaten);
        },
        startTimer : function(){
            document.querySelector("#backgroundTimer").innerHTML = this.time
            this.timerInterval = setInterval(() =>{
                this.time -= 1
                document.querySelector("#backgroundTimer").innerHTML = this.time
                if (this.time === 0){
                    clearInterval(this.timerInterval);
                }
            }, 1000);            
        },
        stopTimer : function(){
            clearInterval(this.timerInterval);
            this.time = 12
        },
        randomCategorie : function(jsonDaten){
            if(jsonDaten.length <= 0){
                    document.querySelector("stadtLandSpeedBanner").classList.add("hidden")
                    document.querySelector("endcardBanner").classList.remove("hidden")
                }    
            const randomNumber = [Math.floor(Math.random()*jsonDaten.Begriff.length)]
            let question = jsonDaten.Begriff[randomNumber]
            jsonDaten.Begriff.splice(randomNumber, 1)
            document.querySelector("#taskCategorie").innerHTML = question
        },
        randomLetter : function(jsonDaten){
            const randomNumber = [Math.floor(Math.random()*jsonDaten.Buchstabe.length)]
            let question = jsonDaten.Buchstabe[randomNumber]
            document.querySelector("#taskLetter").innerHTML = question
        }
    }
}

</script>

<style scoped>
.headline {
    font-size: 24px;
    font-family: 'open sans';
    font-weight: bold;
    margin: 30px;
}
.categories {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
}
.categories h3{
    font-size: 30px;
    font-weight: 500;
    font-family: 'open sans';
}
.gameArea{
  display: flex;
  flex-direction: column;
  align-items: center;
}
#taskLetter {
    font-size: 60px;
    font-family: 'open sans';
    font-weight: bold;
    color: #FFA301;
    margin: 30px;
}
.sLSstartBtn , .sLSnextBtn {
    z-index: 5;
    margin: 30px;
    height: 35px;
    width: 130px;
    color: white;
    font-family: 'open sans';
    font-weight: bold;
    font-size: 20px;
    border-radius: 7px;
    border-style: none;
}
.sLSstartBtn {
    background-color: #FFA301;
}
.sLSnextBtn {
    background-color:#545C8F;
}
#backgroundTimer{

    font-size: 300px;
    font-family: 'open sans';
    font-weight: bold;
    color: rgba(255, 255, 255, 0.2);
}
.container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
  text-align: center;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}
</style>