<template>
  <header>
    <div class="t-left pad-16">
      <router-link to="/">
        <img
          src="../assets/Icons/back.svg"
          class="width-32"
          style="filter: invert(1)"
        >
      </router-link>
    </div>
    <div class="t-center">
      <img
        src="assets/Pictures/logo.png"
        class="width-250"
      >
    </div>
    <div class="pad-16" />
  </header>
  <section class="color-white t-center">
    <h6
      class="f-light"
      style="font-size: 1em; opacity: 0.8"
    >
      Für einen unvergesslichen Abend
    </h6>
    <h3>Alle Spiele im Überblick</h3>
    <div class="pad-16" />
  </section>
  <section id="gameOverview">
    <div class="gamelist">
      <div
        v-for="game in games"
        :key="game.name"
        class="gameBox br-24 grid-12 pad-24 pad-top-24"
        :style="'background-color: '+game.color+';'"
        @click="goToGame(game.goTo)"
      >
        <div class="col-sp-9 row-sp-12 color-white">
          <h6 class="f-light">
            {{ game.description }}
          </h6>
          <h3>{{ game.title }}</h3>
        </div>
        <div class="col-sp-3 row-sp-12 t-right">
          <img
            :src="'assets/Pictures/emojis/'+game.image"
            class="width-64"
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {authStore} from "@/store/authStore";
export default {
  name: "GameOverview",
  title: "Game Overview",
  setup() {
    const auth = authStore()

    return {auth}
  },
    data(){
        return{
            games: [
                {
                  name: 'spiel15',
                  title: 'Drink or Lose!',
                  image: "cocktail.png",
                  banner: "entwederOderBanner.jpg",
                  description:"Drink! Or Lose",
                  goTo: "DrinkOrLose",
                  color: "#7ECE4B"
                },

              {
                  name: 'spiel6',
                  title: 'Bombig!',
                  image: "bomb.png",
                  description:"Der explosive Spaß!",
                  goTo: "bombig",
                  color: "#06AAB1",
                },
                {
                  name: 'spiel7',
                  title: 'Flaschendrehen!',
                  image: "bottle.png",
                  description:"Wer küsst zuerst",
                  goTo: "flaschenDrehen",
                  color: "#5C06B1",
                },
                {
                  name: 'spiel3',
                  title: 'Wahrheit oder Pflicht',
                  image: "face-with-peeking-eye.png",
                  description:"Der besondere Kick!",
                  goTo: "wahrheitOderPflicht",
                  color: "#B10606",
                },
                {
                  name: 'spiel8',
                  title: 'Ich habe noch nie!',
                  image: "melting-face.png",
                  description:"Expose deine Freunde!",
                  goTo: "ichHabNochNie",
                  color: "#FFA301",
                },
                {
                  name: 'spiel14',
                  title: 'Entweder Oder!',
                  image: "face-exhaling.png",
                  description:"Tag der Entscheidung!",
                  goTo: "entwederOder",
                  color: "#06AAB1",
                },
                {
                  name: 'spiel9',
                  title: 'Wer in diesem Raum',
                  image: "face-with-monocle.png",
                  description:"Jetzt kommt alles raus!",
                  goTo: "werInDiesemRaum",
                  color: "#A14BCE",
                },
                {
                  name: 'spiel4',
                  title: 'Pantomime',
                  image: "man-dancing.png",
                  description:"Kunst oder kann das weg?",
                  goTo: "pantomime",
                  color: "#5C06B1",
                },
                {
                    name: 'spiel1',
                    title: 'Scharade',
                    image: "microphone.png",
                    description:"Moderner Rätselspaß.",
                    goTo: "scharade",
                    color: "#076AA8",
                },
                {
                  name: 'spiel11',
                  title: 'Wer bin Ich?',
                  image: "question-mark.png",
                  description:"Gute Frage, nächste Frage…",
                  goTo: "werBinIch",
                  color: "#FFA301",
                },
                {
                  name: 'spiel10',
                  title: 'Stadt Land Speed',
                  image: "memo.png",
                  description:"Bern, Bulgarien, keine Ahnung",
                  goTo: "stadtLandSpeed",
                  color: "#06AAB1",
                },
                {
                  name: 'spiel13',
                  title: 'Erotik für Paare!',
                  image: "hot-face.png",
                  description:"Die sexy Fakten",
                  goTo: "erotikFuerPaare",
                  color: "#A14BCE",
                },
                {
                    name: 'spiel2',
                    title: 'Kings Cup',
                    image: "crown.png",
                    description:"Die sexy Fakten",
                    goTo: "kingsCup",
                    color: "#B10606",
                },
                {
                  name: 'spiel5',
                  title: 'Twink or Daddy',
                  image: "shushing-face.png",
                  description:"Der Kinky Spaß am Abend",
                  goTo: "twinkOrDaddy",
                  color: "#3EDB4A",
                },
                {
                  name: 'spiel12',
                  title: 'Pride Scharade',
                  image: "rainbow.png",
                  description:"Erklären für wahre Queens!",
                  goTo: "gayTabu",
                  color: "#7B0FA3",
                },

            ]
        }
    },
    methods: {
        backToHome : function(){
            document.querySelector("#homepageBanner").classList.remove("hidden")
            document.querySelector("#gameOverviewBanner").classList.add("hidden")
            location.reload();
        },
        goToGame : function(gamename){
          gamename = gamename.charAt(0).toUpperCase() + gamename.slice(1)
          this.$router.push({name: gamename})
        },
    }
}
</script>

<style scoped>
.gamelist{
    margin-bottom: 40px;
}
.gameBox{
  max-width: 350px;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  margin-bottom: 24px;
}
.gameBox img{
  opacity: 0.6;
  transform: rotate(15deg);
}
.gameBox h6{
  opacity: 0.8;
}

</style>