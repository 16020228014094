<template>
  <div
    id="bombigRules"
    class="Rules"
    v-if="help"
  >
    <div class="closeBtn">
      <img
        src="/assets/Icons/close.svg"
        alt=""
        class="redBtn"
        @click="help = false"
      >
    </div>
    <h4 class="font-bold">
      Bombig Spielregeln
    </h4><br>
    <span class="font-normal">Das Spiel beginnt. Eine Kategorie (zB. Automarken) wird genannt. Der erste Spieler beginnt zB. mit Audi, der zweite Spieler nennt Tesla. Sobald eine Antwort doppelt genannt wurde, ist die Runde vorbei und der Spieler muss 2 Schlücke trinken. Oder die Bombe explodiert in einem zufälligen Zeitrahmen. Der Spieler der bei der Explosion dran ist, muss 2 Schlücke trinken.
    </span><br><br>
  </div>
  <div
    id="BombigStufenabfrage"
    class="theme-drinkorlose"
  >
    <header>
      <div class="t-left pad-16">
        <router-link to="/gameOverview">
          <img
            src="../assets/Icons/back.svg"
            class="width-32"
            style="filter: invert(1)"
          >
        </router-link>
      </div>
      <div class="pad-16" />
    </header>
    <div class="spielStufen">
      <div
        id="stufe1"
        class="stufenImg stufeFun"
        @click="getStufe('stufe1')"
      >
        <div class="StufenBeschreibung">
          <h3>Für den perfekten Einstieg</h3>
          <h2>Spaß</h2>
        </div>
        <img
          src="../assets/Pictures/emojis/face-with-hand-over-mouth.png"
          class="width-64 stufenIcon"
        >
      </div>
      <div
        id="stufe2"
        class="stufenImg stufeDirty"
        @click="getStufe('stufe2')"
      >
        <div class="StufenBeschreibung">
          <h3>Hier gehts zur Sache</h3>
          <h2>Dirty</h2>
        </div>
        <img
          src="../assets/Pictures/emojis/fire.png"
          class="width-64 stufenIcon"
        >
      </div>
    </div>
    <div class="pad-16 t-center">
      <button
        @click="help = true"
        class="button large secondary"
      >
        Spieleanleitung
      </button>
    </div>
  </div>
  <section
    id="BombigGame"
    class="hidden"
  >
    <div id="bombBody">
      <header class="GameHeader grid-12">
        <div class="col-sp-6 row-sp-12 t-left">
          <img
            src="/assets/Icons/help.svg"
            alt=""
            class="helpsymbol"
            @click="help = true"
          >
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <router-link
            to="gameOverview"
            class="backCloseButton"
          >
            <img
              src="/assets/Icons/close.svg"
            >
          </router-link>
        </div>
      </header>
      <h2>
        BOMBIG
      </h2>
      <img
        src="../assets/Icons/bombe.svg"
        alt=""
        id="bombenBild"
        class="lowOpacity"
      >
      <h3 id="bombigTask" />
      <button
        id="StartBombig"
        @click="StartGame()"
      >
        Start
      </button>
    </div>
  </section>
</template>

<script>
import {authStore} from "@/store/authStore";

const minTime = 30
const maxTime = 60
let bombTime = Math.floor(Math.random() * (maxTime - minTime)) + minTime;
const bombTL = new gsap.timeline();
import json from '@/jsonDaten/bombig.json';
import { gsap } from 'gsap';
import {addStore} from "@/store/addStore";

let stufe = null
export default {
  name : "Bombig",
  title : "Bombig",
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
  data(){
    return{
      bombigDaten : json,
      help: false
    }
  },
  methods : {
    getStufe : function(value){
      // Hier IF abfrage ob Premium account vorhanden
      stufe = value
      console.log(stufe)
      switch(stufe){
        case "stufe2":
          if(this.auth.premium){
            break;
          }
          else{
            console.log("kauf dir Premium");
            return
          }
      }
      document.querySelector("#BombigStufenabfrage").classList.add("hidden")
      document.querySelector("#BombigGame").classList.remove("hidden")
      return stufe
    },
    backToHome : function(){
      document.querySelector("#homepageBanner").classList.remove("hidden")
      document.querySelector("#bombigBanner").classList.add("hidden")
      location.reload();
    },
    helpmenu : function(){
      document.querySelector("#bombigRules").classList.toggle("hidden");
    },
    StartGame : function(){
      document.querySelector("#StartBombig").classList.add("hidden")
      document.querySelector("#bombenBild").classList.add("lowOpacity")
      this.bombTimer()
      renderBombigWords(this.bombigDaten)
      function renderBombigWords(renderElements){
        switch (stufe){
          case "stufe1":
            randomContent(renderElements.Stufe1)
            break;
          case "stufe2":
            randomContent(renderElements.Stufe2)
            break;
        }
        function randomContent(AllContent){
          let content = AllContent
          if(content.length >= 1){
            const randomNumber = [Math.floor(Math.random()*content.length)]
            let bombword = content[randomNumber]
            content.splice(randomNumber, 1)
            document.querySelector("#bombigTask").innerHTML = bombword
          }
          else{
            document.querySelector("#bombigBanner").classList.add("hidden")
            document.querySelector("#endCardKC").classList.remove("hidden")
            document.querySelector("#endCardPantomime").classList.remove("hidden")
            document.querySelector("#endCardBombig").classList.add("hidden")
            document.querySelector("#endcardBanner").classList.remove("hidden")
          }
        }
      }
    },
    bombTimer : function(){
      if(bombTime >= 1){
        bombTime--
        this.bombTickAnimation()
        setTimeout(this.bombTimer, 1000)
        console.log(bombTime)
      }
      else{
        console.log("buuuum")
        bombTime = Math.floor(Math.random() * (maxTime - minTime)) + minTime;
        document.querySelector("#StartBombig").innerHTML= "Neues Wort"
        bombAnimation()
      }
      function bombAnimation (){
        document.querySelector("#bombenBild").classList.remove("lowOpacity")
        bombTL.to("#bombenBild", 0.3, {scale: 1.2})
        setTimeout(document.querySelector("#StartBombig").classList.remove("hidden"), 1000)
      }
    },
    bombTickAnimation : function(){

      bombTL.to("#bombenBild", 0.5 ,{rotation:10, transformOrigin:"bottom"})
          .to("#bombenBild", 0.5 ,{rotation:-10, transformOrigin:"bottom"})
    }
  }

}
</script>

<style scoped>
#BombigStufenabfrage{
  position: absolute;
  z-index: 20;
  width: 100%;
  min-height: 100%;
  height: fit-content;
}

#bombBody {
  height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
#bombenBild {
  height: 70%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 20%;
}
#bombBody button{
  border-style: none;
  width: 175px;
  height: 38px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #FFA301 ;
  z-index: 2;
  position: absolute;
  top: 50%;
}
#bombBody h2 {
  color: white;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin: 5%;
  z-index: 2;
}
#bombBody h3{
  color: white;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
  z-index: 2;
  position: absolute;
  top: 50%;
}
.lowOpacity {
  opacity: 0.3;
}
</style>