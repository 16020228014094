<template>
  <div id="drinkOrLoseStufenabfrage">
    <header>
      <div class="t-left pad-16">
        <router-link to="/gameOverview">
          <img
            src="../assets/Icons/back.svg"
            class="width-32"
            style="filter: invert(1)"
          >
        </router-link>
      </div>
      <div class="pad-16" />
    </header>
    <div class="spielStufen">
      <div
        id="stufe1"
        class="stufenImg stufeWarmUp"
        @click="getStufe('stufe1')"
      >
        <div class="StufenBeschreibung">
          <h3>Der Party Spielemix</h3>
          <h2>Warm Up</h2>
        </div>
        <img
          src="../assets/Pictures/emojis/flexed-biceps.png"
          class="width-64 stufenIcon"
        >
      </div>
      <div
        id="stufe2"
        class="stufenImg stufePremium"
        @click="getStufe('stufe2')"
      >
        <div class="StufenBeschreibung">
          <h3>Der Party Spielemix</h3>
          <h2>Premium</h2>
        </div>
        <img
          src="../assets/Pictures/emojis/crown.png"
          class="width-64 stufenIcon"
        >
      </div>
    </div>
  </div>
  <section
    id="drinkOrLoseGameArea"
    class="hidden"
  >
    <div
      id="DOLWahrheitOderPflicht"
      class="hidden"
    >
      <header class="GameHeader grid-12">
        <div class="col-sp-6 row-sp-12 t-left">
          <img
            src="/assets/Icons/help.svg"
            alt=""
            class="helpsymbol"
            @click="helpmenu('wahrheitOderPflicht')"
          >
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <router-link
            to="gameOverview"
            class="backCloseButton"
          >
            <img
              src="/assets/Icons/close.svg"
            >
          </router-link>
        </div>
      </header>
      <div
        id="DOLwOPRules"
        class="Rules hidden"
      >
        <div class="closeBtn">
          <img
            src="../assets/Icons/close.svg"
            alt=""
            class="redBtn"
            @click="helpmenu('wahrheitOderPflicht')"
          >
        </div>
        <h4 class="font-bold">
          Wahrheit oder Pflicht Spielregeln
        </h4><br>
        <span class="font-normal">Wähle zwischen Wahrheit, Pflicht und Zufall aus. Bei „Wahrheit“ wird dem Ausgewählten eine Frage gestellt, die er wahrheitsgemäß beantworten muss; kommt jedoch eine „Pflicht“, muss der Ausgewählte die Aufgabe absolvieren. Sollte sich ein Spieler weigern, die Aufgabe zu erfüllen oder die Frage zu beantworten, muss dieser 2 Schlücke trinken. Danach ist der nächste Spieler an der Reihe.
        </span><br><br>
      </div>
      <div class="WoPheadline">
        <h1
          id="WoPh1"
          class="font-bold"
        >
          {{ title }}
        </h1>
      </div>
      <h3
        id="DOLWoPtoDo"
        class="font-bold"
      >
        Drücke einen der Button um zu starten
      </h3>
      <div class="gameMenu">
        <div id="DOLWoPBtnHidden">
          <div class="btns">
            <button
              id="DOLwahrheit-btn"
              @click="wahrheitBtn()"
            >
              Wahrheit
            </button>
            <p>oder</p>
            <button
              id="DOLpflicht-btn"
              @click="pflichtBtn()"
            >
              Pflicht
            </button>
          </div>
          <div class="btns">
            <button
              id="DOLzufall-btn"
              @click="zufallsBtn()"
            >
              Zufall
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      id="DOLIchHabNochNie"
      class="hidden"
    >
      <div
        id="DOLichHabNochNieRules"
        class="Rules hidden"
      >
        <div class="closeBtn">
          <img
            src="../assets/Icons/close.svg"
            alt=""
            class="redBtn"
            @click="helpmenu('ichHabNochNie')"
          >
        </div>
        <h4 class="font-bold">
          Ich hab noch nie Spielregeln
        </h4><br>
        <span class="font-normal">Das Spiel beginnt mit den „Ich hab noch nie“-Fragen und alle Freunde, die es schon mal getan haben, müssen 1 Schluck trinken. Danach wird die nächste Ich hab noch nie Frage in die Runde gestellt.
        </span><br><br>
      </div>
      <header class="GameHeader grid-12">
        <div class="col-sp-6 row-sp-12 t-left">
          <img
            src="/assets/Icons/help.svg"
            alt=""
            class="helpsymbol"
            @click="helpmenu('ichHabNochNie')"
          >
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <router-link
            to="gameOverview"
            class="backCloseButton"
          >
            <img src="/assets/Icons/close.svg">
          </router-link>
        </div>
      </header>
      <h1
        class="DOLiHNNheadline font-bold"
        style="color: #ffffff;"
      >
        Ich hab noch nie
      </h1>
      <h3
        id="DOLiHNNTask"
        class="font-bold"
        style="color: #ffffff;"
      >
        Drücke auf "Start" um zu beginnen
      </h3>
      <div class="gameMenu">
        <button
          id="DOLiHNNweiter"
          @click="DOLiHNNrender(iHNNDaten)"
        >
          Start
        </button>
      </div>
    </div>
    <div
      id="DOLBombig"
      class="hidden"
    >
      <div
        id="DOLbombigRules"
        class="Rules hidden"
      >
        <div class="closeBtn">
          <img
            src="../assets/Icons/close.svg"
            alt=""
            class="redBtn"
            @click="helpmenu('bombig')"
          >
        </div>
        <h4 class="font-bold">
          Bombig Spielregeln
        </h4><br>
        <span class="font-normal">Das Spiel beginnt. Eine Kategorie (zB. Automarken) wird genannt. Der erste Spieler beginnt zB. mit Audi, der zweite Spieler nennt Tesla. Sobald eine Antwort doppelt genannt wurde, ist die Runde vorbei und der Spieler muss 2 Schlücke trinken. Oder die Bombe explodiert in einem zufälligen Zeitrahmen. Der Spieler der bei der Explosion dran ist, muss 2 Schlücke trinken.</span><br><br>
      </div>
      <header class="GameHeader grid-12">
        <div class="col-sp-6 row-sp-12 t-left">
          <img
            src="/assets/Icons/help.svg"
            alt=""
            class="helpsymbol"
            @click="helpmenu('bombig')"
          >
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <router-link
            to="gameOverview"
            class="backCloseButton"
          >
            <img src="/assets/Icons/close.svg">
          </router-link>
        </div>
      </header>
      <h2>
        BOMBIG
      </h2>
      <img
        src="../assets/Icons/bombe.svg"
        alt=""
        id="DOLbombenBild"
        class="lowOpacity"
      >
      <h3 id="DOLbombigTask" />
      <div class="gameMenu">
        <button
          class="font-bold"
          id="DOLStartBombig"
          @click="StartBombigGame()"
        >
          Start
        </button>
      </div>
    </div>
    <div
      id="DOLEntwederOder"
      class="hidden"
    >
      <div
        id="DOLentwederOderRules"
        class="Rules hidden"
      >
        <div class="closeBtn">
          <img
            src="../assets/Icons/close.svg"
            alt=""
            class="redBtn"
            @click="helpmenu('entwederOder')"
          >
        </div>
        <h4 class="font-bold">
          Entweder oder Spielregeln
        </h4><br>
        <span class="font-normal">Das Spiel beginnt. Euch wird eine Aufgabe gestellt. Entscheidet jeweils für euch selber. Es kommt zur Abstimmung. Das Team, welches weniger Stimmen für seine Entscheidung erhalten hat und somit in der Minderheit liegt, muss 2 Schlücke trinken. Die Abstimmung kann zB. für Antwort A mit einem Daumen Hoch und für Antwort B mit einem Daumen runter erfolgen.</span><br><br>
      </div>
      <header class="GameHeader grid-12">
        <div class="col-sp-6 row-sp-12 t-left">
          <img
            src="/assets/Icons/help.svg"
            alt=""
            class="helpsymbol"
            @click="helpmenu('entwederOder')"
          >
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <router-link
            to="gameOverview"
            class="backCloseButton"
          >
            <img src="/assets/Icons/close.svg">
          </router-link>
        </div>
      </header>
      <h1 class="DOLentwederOderheadline">
        Entweder oder
      </h1>
      <h3 id="DOLentwederOderTask">
        Drücke auf Start
      </h3>
      <div class="gameMenu">
        <button
          id="DOLentwederOderBtn"
          @click="startEntwederOderGame()"
        >
          Start
        </button>
      </div>
    </div>
    <div
      id="DOLWerInDiesemRaum"
      class="hidden"
    >
      <div
        id="DOLwIDRRules"
        class="Rules hidden"
      >
        <div class="closeBtn">
          <img
            src="../assets/Icons/close.svg"
            alt=""
            class="redBtn"
            @click="helpmenu('werInDiesemRaum')"
          >
        </div>
        <h4 class="font-bold">
          Wer in diesem Raum Spielregeln
        </h4><br>
        <span class="font-normal">Das Spiel beginnt. Euch wird eine Aufgabe gestellt. Entscheidet jeweils für euch selber. Es kommt zur Abstimmung. Zeigt gleichzeitig auf die Person mit eurem Zeigefinger, für den ihr euch entschieden habt. Die Person mit den meisten Stimmen muss 2 Schlücke trinken. Bei Gleichstand müssen mehrere Personen trinken.</span><br><br>
      </div>
      <header class="GameHeader grid-12">
        <div class="col-sp-6 row-sp-12 t-left">
          <img
            src="/assets/Icons/help.svg"
            alt=""
            class="helpsymbol"
            @click="helpmenu('werInDiesemRaum')"
          >
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <router-link
            to="gameOverview"
            class="backCloseButton"
          >
            <img src="/assets/Icons/close.svg">
          </router-link>
        </div>
      </header>
      <h2 style="margin-top: 20vh;">
        Wer in diesem Raum
      </h2>
      <h3 id="DOLwIDRTask" />
      <div class="gameMenu">
        <button
          id="DOLStartwIDR"
          class="font-bold"
          @click="StartWerInDiesemRaumGame()"
        >
          Start
        </button>
      </div>
    </div>
    <div class="gameMenu">
      <button
        id="nextGameBtn"
        @click="nextGame()"
      >
        Weiter
      </button>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
import WahrheitOderPflichtJson from '@/jsonDaten/WoP.JSON'
import IchHabNochNieJson from '@/jsonDaten/ichHabNochNie.json'
import BombigJson from '@/jsonDaten/bombig.json'
import EntwederOderJson from '@/jsonDaten/entwederOder.json'
import werInDiesemRaumJson from '@/jsonDaten/werInDiesemRaum.json'
import {authStore} from "@/store/authStore";

const WoPTL = new gsap.timeline()
let stufe = null
const minTime = 30
const maxTime = 60
let bombTime = Math.floor(Math.random() * (maxTime - minTime)) + minTime;
const bombTL = new gsap.timeline();

export default {
  name: "DrinkOrLose",
  title: "Drink or Lose",
  setup() {
    const auth = authStore()

    return {auth}
  },
  data(){
    return{
      WoPDaten : WahrheitOderPflichtJson,
      iHNNDaten :IchHabNochNieJson,
      bombigDaten : BombigJson,
      entwederOderDaten : EntwederOderJson,
      wIDRDaten : werInDiesemRaumJson,
      title: 'WAHRHEIT ODER PFLICHT'
    }
  },
  methods: {
    getStufe : function(value){
      stufe = value
      if(stufe === "stufe2"){
        if(this.auth.premium){
          console.log("Premium vorhanden")
          this.nextGame()
          document.querySelector("#drinkOrLoseStufenabfrage").classList.add("hidden")
          document.querySelector("#drinkOrLoseGameArea").classList.remove("hidden")
          return
        }
        else{
          console.log("kauf dir Premium");
          this.auth.buyModal = true
          return
        }
      }
      this.nextGame()
      document.querySelector("#drinkOrLoseStufenabfrage").classList.add("hidden")
      document.querySelector("#drinkOrLoseGameArea").classList.remove("hidden")
      return stufe
    },
    backToHome : function(){
      location.reload();
    },
    helpmenu : function(value){
      switch(value){
        case 'wahrheitOderPflicht':
          document.querySelector("#DOLwOPRules").classList.toggle("hidden");
          break;
        case 'ichHabNochNie':
          document.querySelector("#DOLichHabNochNieRules").classList.toggle("hidden");
          break;
        case 'bombig':
          document.querySelector("#DOLbombigRules").classList.toggle("hidden");
          break;
        case 'entwederOder':
          document.querySelector("#DOLentwederOderRules").classList.toggle("hidden");
          break;
        case 'werInDiesemRaum':
          document.querySelector("#DOLwIDRRules").classList.toggle("hidden");
          break;
      }
    },
    nextGame : function(){
      const randomGameNumber = Math.floor(Math.random()*5)
      document.querySelector("#DOLWahrheitOderPflicht").classList.add("hidden")
      document.querySelector("#DOLIchHabNochNie").classList.add("hidden")
      document.querySelector("#DOLBombig").classList.add("hidden")
      document.querySelector("#DOLEntwederOder").classList.add("hidden")
      document.querySelector("#DOLWerInDiesemRaum").classList.add("hidden")
      document.querySelector("#nextGameBtn").classList.add("hidden")

      switch(randomGameNumber){
        case 0:
          document.querySelector("#DOLWoPtoDo").innerHTML = "Drücke einen der Button um zu starten"
          document.querySelector("#DOLWahrheitOderPflicht").classList.remove("hidden")
          document.querySelector("#DOLWoPBtnHidden").classList.remove("hidden")
          break;
        case 1:
          document.querySelector("#DOLiHNNweiter").classList.remove("hidden")
          document.querySelector("#DOLiHNNTask").innerHTML = "Drücke auf \"Start\" um zu beginnen"
          document.querySelector("#DOLIchHabNochNie").classList.remove("hidden")
          break;
        case 2:
          document.querySelector("#DOLbombigTask").innerHTML = ""
          document.querySelector("#DOLStartBombig").classList.remove("hidden")
          document.querySelector("#DOLbombenBild").classList.add("lowOpacity")
          document.querySelector("#DOLBombig").classList.remove("hidden")
          break;
        case 3:
          document.querySelector("#DOLentwederOderBtn").classList.remove("hidden")
          document.querySelector("#DOLentwederOderTask").innerHTML = "Drücke auf Start"
          document.querySelector("#DOLEntwederOder").classList.remove("hidden")
          break;
        case 4:
          document.querySelector("#DOLStartwIDR").classList.remove("hidden")
          document.querySelector("#DOLwIDRTask").innerHTML = ""
          document.querySelector("#DOLWerInDiesemRaum").classList.remove("hidden")
          break;
      }
    },
    // Wahrheit oder Pflicht Game
    wahrheitBtn : function(){
      this.DOLwOPrender(this.WoPDaten,"wahrheit", stufe )
      this.title = 'WAHRHEIT'
      document.querySelector("#DOLWoPBtnHidden").classList.add("hidden")
      document.querySelector("#nextGameBtn").classList.remove("hidden")
    },
    pflichtBtn : function(){
      this.DOLwOPrender(this.WoPDaten,"pflicht", stufe )
      this.title = 'PFLICHT'
      document.querySelector("#DOLWoPBtnHidden").classList.add("hidden")
      document.querySelector("#nextGameBtn").classList.remove("hidden")
    },
    zufallsBtn : function(){
      const randomizer = Math.round(Math.random() );
      if(randomizer == 0){
        this.wahrheitBtn()
      }
      else if(randomizer == 1){
        this.pflichtBtn()
      }
    },
    DOLwOPrender : function(json, task , stufe){
      const toDo = document.querySelector("#DOLWoPtoDo")
      function randomContent(AllContent){
        const randomNumber = [Math.floor(Math.random()*AllContent.length)]
        if(AllContent.length <= 0){
          document.querySelector("drinkOrLoseBanner").classList.add("hidden")
          document.querySelector("endcardBanner").classList.remove("hidden")
        }
        let question = AllContent[randomNumber]
        AllContent.splice(randomNumber, 1)
        console.log(AllContent.length)
        toDo.innerHTML = question
      }
      if(task == "wahrheit"){
        switch (stufe){
          case "stufe1":
            randomContent(json.Wahrheit.stufe1.Fragen)
            break;
          case "stufe2":
            console.log(Math.floor(Math.random()*3))
            switch(Math.floor(Math.random()*3)){

              case 0:
                randomContent(json.Wahrheit.stufe1.Fragen)
                break;
              case 1:
                randomContent(json.Wahrheit.stufe2.Fragen)
                break;
              case 2:
                randomContent(json.Wahrheit.stufe3.Fragen)
                break;
            }
        }
      }
      else if(task == "pflicht"){
        switch (stufe){
          case "stufe1":
            randomContent(json.Pflicht.stufe1.Pflichten)
            break;
          case "stufe2":
            switch(Math.floor(Math.random()*3)){
              case 0:
                randomContent(json.Pflicht.stufe1.Pflichten)
                break;
              case 1:
                randomContent(json.Pflicht.stufe2.Pflichten)
                break;
              case 2:
                randomContent(json.Pflicht.stufe3.Pflichten)
                break;
            }
        }
      }
    },
    // Ich hab noch nie Game
    DOLiHNNrender : function(jsonDaten){
      document.querySelector("#DOLiHNNweiter").classList.add("hidden")
      document.querySelector("#nextGameBtn").classList.remove("hidden")
      function randomContent(AllContent){
        if(AllContent.length <= 0){
          document.querySelector("drinkOrLoseBanner").classList.add("hidden")
          document.querySelector("endcardBanner").classList.remove("hidden")
        }
        const randomNumber = [Math.floor(Math.random()*AllContent.length)]
        let question = AllContent[randomNumber]
        AllContent.splice(randomNumber, 1)
        document.querySelector("#DOLiHNNTask").innerHTML = question
      }
      switch (stufe){
        case "stufe1":
          randomContent(jsonDaten.stufe1)
          break;
        case "stufe2":
          switch(Math.floor(Math.random()*5)){
            case 0:
              randomContent(jsonDaten.stufe1)
              break;
            case 1:
              randomContent(jsonDaten.stufe2)
              break;
            case 2:
              randomContent(jsonDaten.stufe3)
              break;
            case 3:
              randomContent(jsonDaten.stufe4)
              break;
            case 4:
              randomContent(jsonDaten.stufe5)
              break;
          }
      }

    },
    // Bombig Game
    StartBombigGame : function(){
      document.querySelector("#DOLStartBombig").classList.add("hidden")
      document.querySelector("#DOLbombenBild").classList.add("lowOpacity")
      this.bombTimer()
      renderBombigWords(this.bombigDaten)
      function renderBombigWords(renderElements){
        switch (stufe){
          case "stufe1":
            randomContent(renderElements.Stufe1)
            break;
          case "stufe2":
            switch(Math.round(Math.random())){

              case 0:
                randomContent(renderElements.Stufe1)
                break;
              case 1:
                randomContent(renderElements.Stufe2)
                break;
            }
        }

        function randomContent(AllContent){
          let content = AllContent
          if(content.length >= 1){
            const randomNumber = [Math.floor(Math.random()*content.length)]
            let bombword = content[randomNumber]
            content.splice(randomNumber, 1)
            document.querySelector("#DOLbombigTask").innerHTML = bombword
          }
          else{
            document.querySelector("drinkOrLoseBanner").classList.add("hidden")
            document.querySelector("endcardBanner").classList.remove("hidden")

          }
        }
      }
    },
    bombTimer : function(){
      if(bombTime >= 1){
        bombTime--
        this.bombTickAnimation()
        setTimeout(this.bombTimer, 1000)
        console.log(bombTime)
      }
      else{
        console.log("buuuum")
        bombTime = Math.floor(Math.random() * (maxTime - minTime)) + minTime;
        bombAnimation()
      }
      function bombAnimation (){
        document.querySelector("#DOLbombenBild").classList.remove("lowOpacity")
        bombTL.to("#DOLbombenBild", 0.3, {scale: 1.2})
        setTimeout(document.querySelector("#nextGameBtn").classList.remove("hidden"), 1000)
      }
    },
    bombTickAnimation : function(){

      bombTL.to("#DOLbombenBild", 0.5 ,{rotation:10, transformOrigin:"bottom"})
          .to("#DOLbombenBild", 0.5 ,{rotation:-10, transformOrigin:"bottom"})
    },
    // Entweder Oder
    startEntwederOderGame : function (){
      document.querySelector("#DOLentwederOderBtn").classList.add("hidden")
      document.querySelector("#nextGameBtn").classList.remove("hidden")
      function randomContent(AllContent){

        if(AllContent.length <= 0){
          document.querySelector("drinkOrLoseBanner").classList.add("hidden")
          document.querySelector("endcardBanner").classList.remove("hidden")
        }
        const randomNumber = [Math.floor(Math.random()*AllContent.length)]
        let question = AllContent[randomNumber]
        AllContent.splice(randomNumber, 1)
        document.querySelector("#DOLentwederOderTask").innerHTML = question
      }
      switch (stufe){
        case "stufe1":
          randomContent(this.entwederOderDaten.stufe1)
          break;
        case "stufe2":
          switch(Math.round(Math.random())){

            case 0:
              randomContent(this.entwederOderDaten.stufe1)
              break;
            case 1:
              randomContent(this.entwederOderDaten.stufe2)
              break;
          }
      }
    },
    // Wer in diesem Raum
    StartWerInDiesemRaumGame : function(){
      document.querySelector("#DOLStartwIDR").classList.add("hidden")
      document.querySelector("#nextGameBtn").classList.remove("hidden")
      switch (stufe){
        case "stufe1":
          randomContent(this.wIDRDaten.stufe1)
          break;
        case "stufe2":
          switch(Math.round(Math.random())){

            case 0:
              randomContent(this.wIDRDaten.stufe1)
              break;
            case 1:
              randomContent(this.wIDRDaten.stufe2)
              break;
          }
      }
      function randomContent(AllContent){
        let content = AllContent
        if(content.length >= 1){
          const randomNumber = [Math.floor(Math.random()*content.length)]
          let wIDRWord = content[randomNumber]
          content.splice(randomNumber, 1)
          document.querySelector("#DOLwIDRTask").innerHTML = wIDRWord
        }
        else{

          document.querySelector("drinkOrLoseBanner").classList.add("hidden")
          document.querySelector("endcardBanner").classList.remove("hidden")
        }

      }
    }
  }
}
</script>


<style scoped>
#drinkOrLoseStufenabfrage {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: fit-content;
}
/***************** Wahrheit oder Pflicht *********************/
#DOLWahrheitOderPflicht{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 5%;

}
.WoPheadline{
  margin-top: 20vh;
}
.WoPheadline h1{
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  word-spacing: 5px;
  text-align: center;
}
.DOLWoPhelpsymbol{
  position: absolute;
  right: 70px;
  top: 20px;
}
.btns{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
#DOLwahrheit-btn{
  background-color: #545C8F;
  width: 210px;
  height: 80px;
  border-style: none;
  color: white;
  font-size: 26px;
  font-weight: bold;
  border-radius: 7px;

}
#DOLpflicht-btn{
  background-color: #FFA301;
  width: 210px;
  height: 80px;
  border-style: none;
  color: white;
  font-size: 26px;
  font-weight: bold;
  border-radius: 7px;
}
#DOLWahrheitOderPflicht p{
  color: white;
  font-size: 20px;
  font-weight: lighter;
}
#DOLzufall-btn{
  background: linear-gradient(90deg, rgba(84,92,143,1) 0%, rgba(84,92,143,1) 50%, rgba(255,163,1,1) 50%);
  font-size: 20px;
  font-weight: bold;
  color: white;
  border: none;
  padding: 12px 50px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
}
#DOLWoPtoDo {
  color: white;
  margin: 0px 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
/******************Ich hab noch nie ******************/
#DOLIchHabNochNie{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;

}
.DOLiHNNheadline {
  font-family: 'open sans';
  font-size: 24px;
  font-weight: bold;
  margin-top: 20vh;
  width: 100%;
  text-align: center;
}
#DOLiHNNTask{
  font-weight: 600;
  font-family: 'open sans';
  font-size: 30px;
  text-align: center;
  margin-top: 32px;
}
#DOLiHNNweiter{
  width: 130px;
  height: 35px;
  font-family: 'open sans';
  font-weight: bold;
  font-size: 20px;
  border-style: none;
  border-radius: 7px;
  background-color: #FFA301;
  color: white;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
  width: 100%;
  left: 0;
}
/*****************Bombig *********************/
#DOLBombig {
  height: 90vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
#DOLbombenBild {
  height: 70%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 20%;
}
#DOLBombig button{
  border-style: none;
  width: 175px;
  height: 38px;
  border-radius: 7px;
  font-size: 16px;
  color: white;
  background-color: #FFA301;
}
#DOLBombig h2 {
  color: white;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin: 5%;
  z-index: 2;
}
#DOLBombig h3{
  color: white;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
  z-index: 2;
  position: absolute;
  top: 50%;
}
.lowOpacity {
  opacity: 0.3;
}
/***************Entweder Oder ******************/
#DOLEntwederOder{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  height: 65vh;
}
#DOLEntwederOder h3 , #DOLEntwederOder h1{
  font-family: 'open sans';
  color: white;
  font-size: 24px;
  font-weight: bold;
}
#DOLEntwederOder button{
  width: 175px;
  height: 35px;
  color: white;
  background-color: #FFA301;
  border-radius: 7px;
  border-style: none;
  font-size: 16px;
  font-family: 'open sans';
  font-weight: bold;
}
/**********************Wer in diesem Raum *********************/
#DOLWerInDiesemRaum {
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 30px;

}

#DOLWerInDiesemRaum button{
  border-style: none;
  width: 175px;
  height: 38px;
  border-radius: 7px;
  font-size: 16px;
  color: white;
  background-color: #FFA301 ;

}
#DOLWerInDiesemRaum h2 {
  color: white;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin: 5%;
  z-index: 2;
}
#DOLWerInDiesemRaum h3{
  color: white;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
  z-index: 2;
  text-align: center;
}

#nextGameBtn {
  border-style: none;
  width: 175px;
  height: 38px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #FFA301 ;
  z-index: 2;
  position: absolute;
  bottom: 10%;
  left: calc(50% - 88px);
}
</style>