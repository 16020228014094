<template>
  <header class="GameHeader grid-12">
    <div class="col-sp-6 row-sp-12 t-left" />
    <div class="col-sp-6 row-sp-12 t-right">
      <router-link
        to="gameOverview"
        class="backCloseButton"
      >
        <img
          src="/assets/Icons/close.svg"
        >
      </router-link>
    </div>
  </header>
  <section id="endcard">
    <h2 class="font-bold color-white">
      WEITERE SPIELE
    </h2>
    <div class="pad-16" />
    <div>
      <div class="gamelist">
        <div
          v-for="game in games"
          :key="game.name"
          class="gameBox br-24 grid-12 pad-24 pad-top-24"
          :style="'background-color: '+game.color+';'"
          @click="goToGame(game.goTo)"
        >
          <div class="col-sp-9 row-sp-12 color-white">
            <h6 class="f-light">
              {{ game.description }}
            </h6>
            <h3>{{ game.title }}</h3>
          </div>
          <div class="col-sp-3 row-sp-12 t-right">
            <img
              :src="'assets/Pictures/emojis/'+game.image"
              class="width-64"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="pad-16 t-center theme-drinkorlose">
      <router-link
        to="/"
        class="button large primary"
      >
        Zurück zum Start
      </router-link>
    </div>
  </section>
</template>

<script>
import {authStore} from "@/store/authStore";

export default {
  name: "Endcard",
  title: "Endcard",
  setup() {
    const auth = authStore()

    return {auth}
  },
  data(){
    return{
      games: [
        {
          name: 'spiel1',
          title: 'Scharade',
          image: "pencil.png",
          description:"Moderner Rätselspaß.",
          goTo: "scharade",
          color: "#076AA8",
        },
        {
          name: 'spiel12',
          title: 'Gaytabuu!',
          image: "rainbow.png",
          description:"Erklären für wahre Queens!",
          goTo: "gayTabu",
          color: "#7B0FA3",
        },
        {
          name: 'spiel11',
          title: 'Wer bin Ich?',
          image: "question-mark.png",
          description:"Gute Frage, nächste Frage…",
          goTo: "werBinIch",
          color: "#FFA301",
        },
        {
          name: 'spiel10',
          title: 'Stadt Land Speed',
          image: "memo.png",
          description:"Bern, Bulgarien, keine Ahnung",
          goTo: "stadtLandSpeed",
          color: "#3EDB4A",
        },
        {
          name: 'spiel2',
          title: 'Kings Cup',
          image: "crown.png",
          description:"Die sexy Fakten",
          goTo: "kingsCup",
          color: "#B10606",
        },

      ]
    }
  },
  methods: {
    backToHome : function(){
      document.querySelector("#homepageBanner").classList.remove("hidden")
      document.querySelector("#gameOverviewBanner").classList.add("hidden")
      location.reload();
    },
    goToGame : function(gamename){
      gamename = gamename.charAt(0).toUpperCase() + gamename.slice(1)
      this.$router.push({name: gamename})
    },
  }
}
</script>


<style scoped>
#endcard{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}
.gamelist{
  margin-bottom: 40px;
}
.gameBox{
  max-width: 350px;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  margin-bottom: 24px;
}
.gameBox img{
  opacity: 0.6;
  transform: rotate(15deg);
}
.gameBox h6{
  opacity: 0.8;
}

</style>