<template>
  <header />
</template>

<script>
export default {
  name: "MainNavbar"
}
</script>

<style scoped>




</style>