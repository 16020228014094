<template>
  <div
    id="KCrules"
    class="Rules"
    v-if="help"
  >
    <div class="closeBtn">
      <img
        src="@/assets/Icons/close.svg"
        alt=""
        class="redBtn"
        @click="help = false"
      >
    </div>
    <div style="overflow-y: scroll; max-height: 80vh">
      <h4>Die Kings Cup Regeln</h4><br>
      <span>Die Regeln des Kings Cup Trinkspiels sind von Region zu Region unterschiedlich. Das Spiel kommt aus den USA und viele der Regeln reimen sich auf den Namen der gezogenen Karte.

        In der Regel beginnt der jüngste/ älteste/ schönste/ unattraktivste/ betrunkenste/ nüchternste Spieler und zieht als erster eine Karte. Anschließend geht es im Uhrzeigersinn reihum.

        Muss ein Spieler trinken, trinkt er immer „einen Schluck“. Wie viel genau das ist, das macht ihr am besten unter euch aus.
      </span><br><br>
      <h4>Karten</h4>
      <h5>Zwei</h5>
      <span><strong>Verteile 2</strong>: Zieht ein Spieler eine Zwei, kann er zwei Schlücke verteilen, entweder beide an einen, oder jeweils einen an zwei verschiedene Spieler.
      </span><br><br>
      <h5>Drei</h5>
      <span><strong>Verteile 1</strong>: Der Spieler darf einen Schluck an einen anderen Spieler verteilen.
      </span><br><br>
      <h5>Vier</h5>
      <span><strong>Verteile 2, Trinke 2</strong>: Zieht ein Spieler eine Vier, trinkt er zwei Schlücke und verteilt zwei Schlücke an wen er möchte.
      </span><br><br>
      <h5>Fünf</h5>
      <span><strong>Für die Männer</strong>: Wird eine Fünf gezogen, müssen alle Männer trinken.
      </span><br><br>
      <h5>Sechs</h5>
      <span><strong>Für die Damen</strong>:  Alle Damen müssen trinken.
      </span><br><br>
      <h5>Sieben</h5>
      <span><strong>Der Daumen-Meister (Thumb Master)</strong>: Ziehst du eine Sieben, bist du ab sofort der Daumen-Meister. Du kannst zu jedem Zeitpunkt des Spieles den Daumen deiner linken oder rechten Hand unauffällig auf den Tisch legen. Alle Spieler müssen es dir nachmachen. Der Letzte Spieler, der seinen Daumen auf den Tisch legt, muss trinken.

        Es ist immer der Spieler der Daumen-Meister, der die letzte Sieben gezogen hat. Zieht jemand eine Sieben, bevor der vorherige Daumen-Meister sein Recht ausgespielt hat, verfällt dieses.
      </span><br><br>
      <h5>Acht</h5>
      <span><strong>Trinkpartner</strong>:  Bei einer Acht kannst du einen Spieler wählen, der ab sofort dein Trinkpartner ist. Immer wenn einer von euch beiden trinken muss, trinkt der andere mit. Wird eine weitere Acht gezogen, können auch drei und mehr Spieler miteinander verbunden werden.

        Wenn alle am Tisch gleichzeitig trinken müssten, werden die Partnerschaften aufgehoben.
      </span><br><br>
      <h5>Neun</h5>
      <span><strong>Reimen</strong>: Ziehst du eine Neun, sagst du ein Wort. Im Uhrzeigersinn müssen nun Reime auf das Wort gefunden werden. Wem zuerst kein neuer Reim mehr einfällt, muss trinken.
      </span><br><br>
      <h5>Zehn</h5>
      <span><strong>Alle Trinken</strong>: Bei einer Zehn trinken alle Spieler einen Schluck und stoßen miteinander an.
      </span><br><br>
      <h5>Bube</h5>
      <span><strong>Kategorien</strong>: Bei einem Buben gibt der Spieler, der ihn gezogen hat, eine Kategorie vor, beispielsweise „Biermarken“. Er beginnt mit der ersten Biermarke und nun müssen im Uhrzeigersinn Biermarken genannt werden. Wem zuerst keine mehr einfällt, muss trinken.

        Weitere Ideen für Kategorie-Runden:
        <ul>
          <li>Cocktails</li>
          <li>Sexpositionen</li>
          <li>Hauptstädte</li>
          <li>Personen mit denen „Spieler“ der Runde rumgemacht haben</li>
          <li>Oscar-Filme</li>
          <li>Pokémon</li>
        </ul>
      </span>
      <h5>Dame</h5>
      <span><strong>Der Fragen-Meister</strong>: Wer ab sofort eine Frage des Frage-Meisters beantwortet, muss einen trinken. Er kann so oft und so viele Fragen stellen, wie er möchte. Es ist immer der Spieler der Fragen-Meister, der die letzte Dame gezogen hat. Eine Gegenfrage gilt nicht als Beantwortung und somit muss der Spieler nicht trinken.
      </span><br><br>
      <h5>König</h5>
      <span><strong>The Kings's Cup</strong>: Die ersten drei Spieler die einen König ziehen, befüllen den Kelch des Königs zu jeweils 1/3 mit ihrem/einem Getränk. Der vierte Spieler der einen König zieht, muss den Becher zügig leertrinken. Das Spiel endet mit dieser Karte. Wer den letzten König zieht, hat verloren.
      </span><br><br>
      <h5>Ass</h5>
      <span><strong>Eine Regel</strong>:  Immer, wenn ein Ass gezogen wird, darf der jeweilige Spieler sich eine neue Regel ausdenken. Wird eine Regel gebrochen, muss der Regelbrecher trinken.

        Hier findest du einige Vorschläge für lustige Kings Cup Regeln:
        <ul>
          <li><strong>Sperma:</strong> Immer wenn jemand trinkt muss er im Anschluss sagen „Schade, dass das kein Sperma war“.</li>
          <li><strong>Der kleine Mann:</strong> Auf dem Rand jedes Glases sitzt ein unsichtbarer kleiner Mann. Bevor man trinkt, muss man ihn vorsichtig absetzen, da er ansonsten ins Getränk fällt. Danach ist er selbstverständlich wieder auf den Rand zu setzen. Sonst sieht er ja nix.</li>
          <li><strong>Zahnlos:</strong> Ab sofort darf man beim Lachen nicht mehr seine Zähne zeigen.</li>
          <li><strong>Der Stirn-Meister:</strong>  Ersetze eine beliebige Karte durch den „Stirn-Meister“. Analog zum Daumen-Meister kann der Spieler jederzeit seine Stirn auf dem Tisch legen. Der letzte Spieler der das tut, muss trinken.</li>
        </ul>
      </span><br><br>
    </div>
  </div>
  <header class="GameHeader grid-12">
    <div class="col-sp-6 row-sp-12 t-left">
      <img
        src="/assets/Icons/help.svg"
        alt=""
        class="helpsymbol"
        @click="help = true"
      >
    </div>
    <div class="col-sp-6 row-sp-12 t-right">
      <router-link
        to="gameOverview"
        class="backCloseButton"
      >
        <img
          src="/assets/Icons/close.svg"
        >
      </router-link>
    </div>
  </header>
  <h1
    style="position: absolute; width: 100%;"
    class="font-bolder color-white t-center pad-top-16"
  >
    KING'S CUP
  </h1>
  <section id="KingsCupGame">
    <div class="hidden">
      <img
        src="@/assets/Icons/KartenSymbole/Herz.svg"
        alt=""
      >
      <img
        src="@/assets/Icons/KartenSymbole/Karo.svg"
        alt=""
      >
      <img
        src="@/assets/Icons/KartenSymbole/Pik.svg"
        alt=""
      >
      <img
        src="@/assets/Icons/KartenSymbole/Kreuz.svg"
        alt=""
      >
    </div>
    <div class="container">
      <div id="cardArea">
        <h2
          id="kingCounter"
          class="font-normal"
        >
          Könige 0 / 4
        </h2>
        <div
          id="bannerCard"
          @click="bannerCardClick()"
        >
          <img
            src="/assets/Icons/logo+schrift-07.svg"
            alt=""
          >
        </div>
        <div
          id="CardPicture"
          @click="nextCard()"
        >
          <!-- Hier werden die Karten eingeblendet -->
          <h3 class="CardName MiddleCardName" />
          <h3 class="CardName LeftCardName" />
          <h3 class="CardName RightCardName" />
          <img
            class="middleSymbol"
            src=""
            alt=""
          >
          <img
            class="leftSymbol"
            src=""
            alt=""
          >
          <img
            class="rightSymbol"
            src=""
            alt=""
          >
        </div>
        <div id="newCardPicture">
          <h3 class="newCardName newMiddleCardName" />
          <h3 class="newCardName newLeftCardName" />
          <h3 class="newCardName newRightCardName" />

          <img
            class="middleSymbol"
            src=""
            alt=""
          >
          <img
            class="leftSymbol"
            src=""
            alt=""
          >
          <img
            class="rightSymbol"
            src=""
            alt=""
          >
        </div>
        <h3 id="toDo" />
        <div id="kingCount" />
      </div>
      <div class="gameMenu">
        <button
          id="newCard"
          class="hidden"
          @click="nextCard()"
        >
          Neue Karte
        </button>
        <button
          id="startGameKC"
          @click="bannerCardClick()"
        >
          Spiel Starten
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
import {addStore} from "@/store/addStore";
import {authStore} from "@/store/authStore";

export default {
  name: "KingsCup",
  title: "Kings Cup",
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
  data(){
    return {
      kingCounter : 0,
      help: false,
      HerzBild : "/assets/Icons/KartenSymbole/Herz.svg",
      Cards : ["Kreuz 2", "Kreuz 3" ,"Kreuz 4", "Kreuz 5" , "Kreuz 6", "Kreuz 7" , "Kreuz 8" , "Kreuz 9" ,"Kreuz 10" ,"Kreuz B" , "Kreuz D" , "Kreuz K" , "Kreuz A",
        "Herz 2", "Herz 3" ,"Herz 4", "Herz 5" , "Herz 6", "Herz 7" , "Herz 8" , "Herz 9" ,"Herz 10" ,"Herz B" , "Herz D" , "Herz K" , "Herz A",
        "Pik 2", "Pik 3" ,"Pik 4", "Pik 5" , "Pik 6", "Pik 7" , "Pik 8" , "Pik 9" ,"Pik 10" ,"Pik B" , "Pik D" , "Pik K" , "Pik A",
        "Karo 2", "Karo 3" ,"Karo 4", "Karo 5" , "Karo 6", "Karo 7" , "Karo 8" , "Karo 9" ,"Karo 10" ,"Karo B" , "Karo D" , "Karo K" , "Karo A"]
    }
  },
  methods: {
    bannerCardClick : function(){
      const oldCard = document.querySelector("#CardPicture");
      const bannerCard = document.querySelector("#bannerCard");

      document.querySelector("#newCard").classList.remove("hidden");
      document.querySelector("#startGameKC").classList.add("hidden");
      this.getRandomCard()
      const bannerCardChange = new gsap.timeline()
      bannerCardChange.to(bannerCard, 1, {x: 500, rotate: 45})
          .to(oldCard, 1, {x: 500, rotate: 45},"-=1")
          .to(oldCard, 0 ,{x:0, rotate: 0})
      setTimeout(()=>{bannerCard.classList.add("hidden")}, 1000)
    },
    nextCard : function(){
      const oldCard = document.querySelector("#CardPicture");
      const cardChange = new gsap.timeline()
      this.getRandomCard()
      cardChange.to(oldCard, 1, {x: 500, rotate: 45})
          .to(oldCard, 0 ,{x:0, rotate: 0})

    },
    getRandomCard : function(){
      const CardButton = document.querySelector("#newCard")
      const CardChallenge = document.querySelector("#toDo")
      const CardName = document.querySelectorAll(".CardName")
      const newCardName = document.querySelectorAll(".newCardName")
      const CardImgSymbol = document.querySelectorAll("#CardPicture img")
      const newCardImgSymbol = document.querySelectorAll("#newCardPicture img")
      const kingCounterTxt = document.querySelector("#kingCounter")



      let CardIndex = getRandomNumber(this.Cards.length)
      let UsedCard = this.Cards.splice(CardIndex , 1)
      console.log(this.Cards.length)
      console.log(UsedCard)
      if (this.kingCounter === 4){
        /*
        document.querySelector("#kingsCupBanner").classList.add("hidden")
        document.querySelector("#endCardKC").classList.add("hidden")
        document.querySelector("#endCardPantomime").classList.remove("hidden")
        document.querySelector("#endCardBombig").classList.remove("hidden")
        document.querySelector("#endcardBanner").classList.remove("hidden")*/
        this.$router.push({name: 'Endcard'})
      }
      else {
        newCardName.forEach((child)=>{child.innerHTML = UsedCard[0].split(' ')[1];})
        setTimeout(()=>{CardName.forEach((child)=>{child.innerHTML = UsedCard[0].split(' ')[1];})}, 990)
        const CardValue = UsedCard[0].split(' ')[1]
        const CardSymbol = UsedCard[0].split(' ')[0]

        switch (CardValue){
          case "2":
            CardChallenge.innerHTML = "Verteile 2 Schlücke";
            break;
          case "3":
            CardChallenge.innerHTML = "Trink 1 Schluck";
            break;
          case "4":
            CardChallenge.innerHTML = "Trink 2 Schlücke & Verteile 2 Schlücke";
            break;
          case "5":
            CardChallenge.innerHTML = "Alle Männer trinken";
            break;
          case "6":
            CardChallenge.innerHTML = "Alle Frauen trinken";
            break;
          case "7":
            CardChallenge.innerHTML = "Du bist der Daumen Master";
            break;
          case "8":
            CardChallenge.innerHTML = "Such dir einen Trinkpartner aus";
            break;
          case "9":
            CardChallenge.innerHTML = "Reimen";
            break;
          case "10":
            CardChallenge.innerHTML = "Alle Trinken";
            break;
          case "B":
            CardChallenge.innerHTML = "Kategorie";
            break;
          case "D":
            CardChallenge.innerHTML = "Du bist Fragenmeister";
            break;
          case "K":
            CardChallenge.innerHTML = "Ein König wurde gezogen";
            switch(this.kingCounter){

              case 0:
                this.kingCounter++;
                kingCounterTxt.innerHTML = "Könige 1 / 4";
                CardChallenge.innerHTML= "Fülle den Kelch des Königs zu 1/3"
                break;
              case 1:
                this.kingCounter++;
                kingCounterTxt.innerHTML = "Könige 2 / 4"
                CardChallenge.innerHTML= "Fülle den Kelch des Königs zu 2/3"
                break;
              case 2:
                this.kingCounter++;
                kingCounterTxt.innerHTML = "Könige 3 / 4"
                CardChallenge.innerHTML= "Fülle Komplett den Kelch des Königs"
                break;
              case 3:
                this.kingCounter++;
                kingCounterTxt.innerHTML = "Könige 4 / 4"
                CardChallenge.innerHTML= "Du hast Verloren! Trink den Kelch aus!"
                CardButton.innerHTML = "Neues Spiel !"
                break;
            }
            break;
          case "A":
            CardChallenge.innerHTML = "Du darfst dir eine Regel aussuchen";
            break;
        }
        switch (CardSymbol){
          case "Herz":
            newCardName.forEach((child)=>{child.style.color = "#a31919"})
            setTimeout(()=>{CardName.forEach((child)=>{child.style.color = "#a31919"})}, 990)
            newCardImgSymbol.forEach((child)=>{child.setAttribute("src", "/assets/Icons/KartenSymbole/Herz.svg")})
            setTimeout(()=>{CardImgSymbol.forEach((child)=>{child.setAttribute("src", "/assets/Icons/KartenSymbole/Herz.svg")})}, 990)
            break;
          case "Karo":
            newCardName.forEach((child)=>{child.style.color = "#a31919"})
            setTimeout(()=>{CardName.forEach((child)=>{child.style.color = "#a31919"})}, 990)
            newCardImgSymbol.forEach((child)=>{child.setAttribute("src", "/assets/Icons/KartenSymbole/Karo.svg")})
            setTimeout(()=>{CardImgSymbol.forEach((child)=>{child.setAttribute("src", "/assets/Icons/KartenSymbole/Karo.svg")})}, 990)
            break;
          case "Kreuz":
            newCardName.forEach((child)=>{child.style.color = "#000000"})
            setTimeout(()=>{CardName.forEach((child)=>{child.style.color = "#000000"})}, 990)
            newCardImgSymbol.forEach((child)=>{child.setAttribute("src", "/assets/Icons/KartenSymbole/Kreuz.svg")})
            setTimeout(()=>{CardImgSymbol.forEach((child)=>{child.setAttribute("src", "/assets/Icons/KartenSymbole/Kreuz.svg")})}, 990)
            break;
          case "Pik":
            newCardName.forEach((child)=>{child.style.color = "#000000"})
            setTimeout(()=>{CardName.forEach((child)=>{child.style.color = "#000000"})}, 990)
            newCardImgSymbol.forEach((child)=>{child.setAttribute("src", "/assets/Icons/KartenSymbole/Pik.svg")})
            setTimeout(()=>{CardImgSymbol.forEach((child)=>{child.setAttribute("src", "/assets/Icons/KartenSymbole/Pik.svg")})}, 990)
            break;


        }
      }
      function getRandomNumber(CardsCount){return Math.floor(Math.random() * CardsCount);}

    }


  }
}
</script>

<style scoped>

#cardArea{
  min-height: 70vh;
  width: calc(100% - 32px);
  z-index: -1;
  background-color: #545C8F;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  overflow: hidden;
}
#kingCounter{
  position: absolute;
  top: -4px;
}
.MiddleCardName, .newMiddleCardName {
  font-size: 100px;
  font-weight: bolder;
  font-family: 'Open sans';
  position: absolute;
  top: 36%;
  left: 50%;
}
.LeftCardName, .newLeftCardName {
  font-size: 40px;
  font-weight: bolder;
  font-family: 'Open sans';
  position: absolute;
  top: 10px;
  left: 10px;
}
.RightCardName, .newRightCardName {
  font-size: 40px;
  font-weight: bolder;
  font-family: 'Open sans';
  position: absolute;
  bottom: 10px;
  right: 10px;
  transform: rotate(180deg);
}
#toDo {
  font-size: 24px;
  font-family: sans-serif;
  margin: 10px;
  text-align: center;
  position: absolute;
  bottom: 10px;
  color: white;
}
#newCard , #startGameKC{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  border: none;
  border-radius: 20px;
  background-color: #545C8F;
  height: 4rem;
  width: 16rem;
}
#CardPicture , #newCardPicture{
  height: 450px;
  width: 300px;
  margin: 20px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  border: 1px black solid;
}
#bannerCard {
  z-index: 20;
  height: 450px;
  width: 300px;
  margin: 20px;
  background-color: grey;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  border: 1px black solid;
}
#bannerCard img {
  margin-top: 70px;
}
#CardPicture {
  z-index: 2;
}
#CardPicture img.middleSymbol, #newCardPicture img.middleSymbol {
  height: 90px;
  width: 90px;
  position: absolute;
  top: calc(50% - 45px);
  right: 50%;
}
#CardPicture img.leftSymbol, #newCardPicture img.leftSymbol {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 60px;
  left: 7px;
}
#CardPicture img.rightSymbol, #newCardPicture img.rightSymbol {
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: 60px;
  right: 7px;
  transform: rotate(180deg);
}

.back-arrow{
  filter: invert(1);
  position: absolute;
  top: 10px;
  left: 10px;
}
.container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
  text-align: center;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}
</style>