<template>
  <footer>
    <div class="footer-text">
      <p>
        <router-link to="/impressum">
          Impressum
        </router-link> | <router-link to="/datenschutz">
          Datenschutz
        </router-link>
      </p>
      <p>© 2022 QUEERGAMES.DE</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

footer{
  width: 100%;
  text-align: center;
}
footer div{
  padding: 16px;
}
footer p, a{
  color: rgba(255, 255, 255,0.6);
  text-decoration: none;
  font-weight: 100;
  font-size: 0.9em;
}
</style>