<template>
  <div
    id="wIDRRules"
    class="Rules hidden"
  >
    <div class="closeBtn">
      <img
        src="/assets/Icons/close.svg"
        alt=""
        class="redBtn"
        @click="helpmenu()"
      >
    </div>
    <h4 class="font-bold">
      Wer in diesem Raum Spielregeln
    </h4><br>
    <span class="font-normal">Das Spiel beginnt. Euch wird eine Aufgabe gestellt. Entscheidet jeweils für euch selber. Es kommt zur Abstimmung. Zeigt gleichzeitig auf die Person mit eurem Zeigefinger, für den ihr euch entschieden habt. Die Person mit den meisten Stimmen muss 2 Schlücke trinken. Bei Gleichstand müssen mehrere Personen trinken.
    </span><br><br>
  </div>
  <section
    id="werInDiesemRaumGame"
    class="theme-drinkorlose"
  >
    <div id="wIDRstufenAbfrage">
      <header>
        <div class="t-left pad-16">
          <router-link to="/gameOverview">
            <img
              src="../assets/Icons/back.svg"
              class="width-32"
              style="filter: invert(1)"
            >
          </router-link>
        </div>
        <div class="pad-16" />
      </header>
      <div class="spielStufen">
        <div
          id="stufe1"
          class="stufenImg stufeFun"
          @click="getStufe('stufe1')"
        >
          <div class="StufenBeschreibung">
            <h3>Für den perfekten Einstieg</h3>
            <h2>Spaß</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/face-with-hand-over-mouth.png"
            class="width-64 stufenIcon"
          >
        </div>
        <div
          id="stufe2"
          class="stufenImg stufeDirty"
          @click="getStufe('stufe2')"
        >
          <div class="StufenBeschreibung">
            <h3>Hier gehts zur Sache</h3>
            <h2>Dirty</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/fire.png"
            class="width-64 stufenIcon"
          >
        </div>
      </div>
      <div class="pad-16 t-center">
        <button
          @click="helpmenu()"
          class="button large secondary"
        >
          Spieleanleitung
        </button>
      </div>
    </div>
    <div
      id="wIDRBody"
      class="hidden"
    >
      <header class="GameHeader grid-12">
        <div class="col-sp-6 row-sp-12 t-left">
          <img
            src="/assets/Icons/help.svg"
            alt=""
            class="helpsymbol"
            @click="helpmenu()"
          >
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <router-link
            to="gameOverview"
            class="backCloseButton"
          >
            <img
              src="/assets/Icons/close.svg"
            >
          </router-link>
        </div>
      </header>
      <h2>
        Wer in diesem Raum
      </h2>
      <h3 id="wIDRTask" />
      <div class="gameMenu">
        <button
          id="StartwIDR"
          class="font-bold"
          @click="StartGame()"
        >
          Start
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import json from '@/jsonDaten/werInDiesemRaum.json'
import {authStore} from "@/store/authStore";
import {addStore} from "@/store/addStore";
let stufe = null;
export default {
  name: "WerInDiesemRaum",
  title: "Wer in diesem Raum?",
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
    data(){
        return{
            wIDRDaten : json
        }
    },
    methods : {
        getStufe : function(value){
            // Hier IF abfrage ob Premium account vorhanden
            stufe = value
            console.log(stufe)
            switch(stufe){
                case "stufe2":
                    if(this.auth.premium){
                        break;
                    }
                    else{
                        console.log("kauf dir Premium");
                        return
                    } 
            }
            document.querySelector("#wIDRstufenAbfrage").classList.add("hidden")
            document.querySelector("#wIDRBody").classList.remove("hidden")
            return stufe
        },
        helpmenu : function(){
            document.querySelector("#wIDRRules").classList.toggle("hidden");
        },
        StartGame : function(){
            document.querySelector("#StartwIDR").innerHTML = "Weiter";
            switch (stufe){
                case "stufe1":
                    randomContent(this.wIDRDaten.stufe1)
                    break;
                case "stufe2":
                    randomContent(this.wIDRDaten.stufe2)
                    break;
            }
            function randomContent(AllContent){
                    let content = AllContent
                    if(content.length >= 1){
                    const randomNumber = [Math.floor(Math.random()*content.length)]
                    let wIDRWord = content[randomNumber]
                    content.splice(randomNumber, 1)
                    document.querySelector("#wIDRTask").innerHTML = wIDRWord
                    }
                    else{
                    document.querySelector("erotikFuerPaareBanner").classList.add("hidden")
                    document.querySelector("endcardBanner").classList.remove("hidden")
                    }
                
            }
        }
    }
}
</script>

<style scoped>
.back-arrow{
    z-index: 25;
}
#wIDRstufenAbfrage{
        position: absolute;
        z-index: 20;
        width: 100%;
        min-height: 100%;
        height: fit-content;
        
}

#wIDRBody {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
}

#wIDRBody button{
    border-style: none;
    width: 175px;
    height: 38px;
    border-radius: 7px;
    font-size: 16px;
    color: white;
    background-color: #FFA301 ;
    z-index: 2;
   
}
#wIDRBody h2 {
    color: white;
    font-family: sans-serif;
    font-size: 28px;
    font-weight: bold;
    z-index: 2;
  text-align: center;
}
#wIDRBody h3{
    color: white;
    font-family: sans-serif;
    font-size: 24px;
    font-weight: bold;
    z-index: 2;
    padding: 16px;
  text-align: center;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}

</style>