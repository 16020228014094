<template>
  <div
    id="scharadeRules"
    class="Rules"
    v-if="help"
  >
    <div class="closeBtn">
      <img
        src="/assets/Icons/close.svg"
        alt=""
        class="redBtn"
        @click="help = false"
      >
    </div>
    <h4 class="font-bold">
      Scharade Spielregeln
    </h4><br>
    <span class="font-normal">Ein Spieler zieht einen Begriff und erklärt ihn seinen Freunden wörtlich,
      ohne das Wort selbst zu nennen oder Teile des Wortes zu benutzen. Derjenige, der das Wort zuerst richtig errät, darf 2 Schlücke verteilen.
    </span>
    <br>
    <br>
  </div>
  <section id="scharadeGame">
    <div
      id="scharadeStufenAbfrage"
      class="theme-drinkorlose"
    >
      <header>
        <div class="t-left pad-16">
          <router-link to="/gameOverview">
            <img
              src="../assets/Icons/back.svg"
              class="width-32"
              style="filter: invert(1)"
            >
          </router-link>
        </div>
        <div class="pad-16" />
      </header>
      <div class="spielStufen">
        <div
          id="stufe1"
          class="stufenImg stufeFun"
          @click="getStufe('stufe1')"
        >
          <div class="StufenBeschreibung">
            <h3>Umschreibe die krasstesten Beschimpfungen</h3>
            <h2>Schimpfscharade!</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/melting-face.png"
            class="width-64 stufenIcon"
          >
        </div>
        <div
          id="stufe2"
          class="stufenImg stufeSecrets"
          @click="getStufe('stufe2')"
        >
          <div class="StufenBeschreibung">
            <h3>Wie erklärt man wörtlich "volles Gummi?"</h3>
            <h2>DIRTY Scharade!</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/heart-on-fire.png"
            class="width-64 stufenIcon"
          >
        </div>
        <div
          id="stufe3"
          class="stufenImg stufeLoveGame"
          @click="getStufe('stufe3')"
        >
          <div class="StufenBeschreibung">
            <h3>Erkläre alle Klassiker</h3>
            <h2>All in One!</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/party-popper.png"
            class="width-64 stufenIcon"
          >
        </div>
        <div
          id="stufe4"
          class="stufenImg stufeWarmUp"
          @click="getStufe('stufe4')"
        >
          <div class="StufenBeschreibung">
            <h3>Promis erklären auf Zeit, schaffst du es?</h3>
            <h2>Wer bin ich!</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/face-with-peeking-eye.png"
            class="width-64 stufenIcon"
          >
        </div>
      </div>
      <div class="pad-16 t-center">
        <button
          @click="help = true"
          class="button large secondary"
        >
          Spieleanleitung
        </button>
      </div>
    </div>
    <div
      id="scharadeBody"
      class="hidden"
    >
      <header class="GameHeader grid-12">
        <div class="col-sp-6 row-sp-12 t-left">
          <img
            src="/assets/Icons/help.svg"
            alt=""
            class="helpsymbol"
            @click="help = true"
          >
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <router-link
            to="gameOverview"
            class="backCloseButton"
          >
            <img
              src="/assets/Icons/close.svg"
            >
          </router-link>
        </div>
      </header>
      <h1
        style="position: absolute; width: 100%;"
        class="color-white pad-top-16 t-center font-bolder"
      >
        Scharade
      </h1>
      <div class="container">
        <h2
          id="scharadeTask"
          class="font-bold"
          @click="getRandomItem()"
        >
          Klicke <span style="color: #FFA301;">hier</span> um das erste Wort zu bekommen
        </h2>
        <h3 class="font-normal">
          Beschreiben / Nachmachen
        </h3>
        <div class="gameMenu">
          <p id="countdown" />
          <div class="centerTimer">
            <h2 id="scharadeBackgroundTimer" />
          </div>
          <button
            id="scharadeTimerStartBtn"
            @click="startTimer()"
          >
            Timer Starten
          </button>
          <button
            id="scharadeTimerStopBtn"
            class="hidden"
            @click="stopTimer()"
          >
            Timer Stoppen
          </button>
          <button @click="getRandomItem">
            neues Wort
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import json from '@/jsonDaten/scharade.json';
import {authStore} from "@/store/authStore";
import {addStore} from "@/store/addStore";
export default {
  name : "Scharade",
  title : "Title",
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
  data(){
    return{
      scharadeDaten: json,
      help: false,
      time: 60,
      timerInterval: null,
      stufe: null
    }
  },
  beforeUnmount() {
    try {
      clearInterval(this.timerInterval)
    }catch (e) {

    }
  },
  methods : {
    getStufe : function(value){
      // Hier IF abfrage ob Premium account vorhanden
      this.stufe = value
      switch(this.stufe){
        case "stufe1":
          console.log(this.stufe)
          document.querySelector("#scharadeStufenAbfrage").classList.add("hidden")
          document.querySelector("#scharadeBody").classList.remove("hidden")
          return this.stufe
        case "stufe2":
          if(this.auth.premium){
            console.log("Premium vorhanden")
            document.querySelector("#scharadeStufenAbfrage").classList.add("hidden")
            document.querySelector("#scharadeBody").classList.remove("hidden")
            return this.stufe
          }
          else{
            console.log("kauf dir Premium");
            return
          }
        case "stufe3":
          if(this.auth.premium){
            console.log("Premium vorhanden")
            document.querySelector("#scharadeStufenAbfrage").classList.add("hidden")
            document.querySelector("#scharadeBody").classList.remove("hidden")
            return this.stufe
          }
          else{
            console.log("kauf dir Premium");
            return
          }
        case "stufe4":
          if(this.auth.premium){
            console.log("Premium vorhanden")
            document.querySelector("#scharadeStufenAbfrage").classList.add("hidden")
            document.querySelector("#scharadeBody").classList.remove("hidden")
            return this.stufe
          }
          else{
            console.log("kauf dir Premium");
            return
          }
      }
    },
    helpmenu : function(){
      document.querySelector("#scharadeRules").classList.toggle("hidden");
    },
    backToHome : function(){
      document.querySelector("#homepageBanner").classList.remove("hidden")
      document.querySelector("#scharadeBanner").classList.add("hidden")
      location.reload();
    },
    getRandomItem : function(){

      function randomContent(AllContent){

        if(AllContent.length <= 0){
          document.querySelector("scharadeBanner").classList.add("hidden")
          document.querySelector("endcardBanner").classList.remove("hidden")
        }
        const randomNumber = [Math.floor(Math.random()*AllContent.length)]
        let question = AllContent[randomNumber]
        AllContent.splice(randomNumber, 1)
        document.querySelector("#scharadeTask").innerHTML = question
      }
      switch (this.stufe){
        case "stufe1":
          randomContent(this.scharadeDaten.stufe1)
          break;
        case "stufe2":
          randomContent(this.scharadeDaten.stufe2)
          break;
        case "stufe3":
          randomContent(this.scharadeDaten.stufe3)
          break;
        case "stufe4":
          randomContent(this.scharadeDaten.stufe4)
          break;
      }

    },
    startTimer() {
      document.querySelector("#scharadeBackgroundTimer").innerHTML = this.time
      this.timerInterval = setInterval(() =>{
        this.time -= 1
        document.querySelector("#scharadeBackgroundTimer").innerHTML = this.time
        if (this.time === 0){
          clearInterval(this.timerInterval);
        }
      }, 1000);
      document.querySelector("#scharadeTimerStartBtn").classList.add("hidden")
      document.querySelector("#scharadeTimerStopBtn").classList.remove("hidden")
    },
    stopTimer : function(){
      clearInterval(this.timerInterval);
      this.time = 60
      document.querySelector("#scharadeTimerStartBtn").classList.remove("hidden")
      document.querySelector("#scharadeTimerStopBtn").classList.add("hidden")
    }
  }
}
</script>

<style scoped>

#scharadeStufenAbfrage{
  position: absolute;
  z-index: 20;
  width: 100%;
  height: fit-content;

}

.vh100{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items:center ;
  justify-content: space-around;
}
h3 {
  margin: 40px 0 0;
}
#scharadeBody button {
  border-style: none;
  border-radius: 7px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  width: 175px;
  height: 38px;
  background-color: #FFA301;
  color: white;
  margin: 10px;
}
#scharadeBackgroundTimer{

  font-size: 300px;
  font-family: 'open sans';
  font-weight: bold;
  color: rgba(255, 255, 255, 0.2);
}
#scharadeTask {
  z-index: 20;
}
.container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
  text-align: center;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}
h2 span{
  font-size: 1em;
}
</style>