<template>
  <header class="GameHeader grid-12">
    <div class="col-sp-6 row-sp-12 t-left">
      <img
        src="/assets/Icons/help.svg"
        alt=""
        class="helpsymbol"
        @click="helpmenu()"
      >
      <div
        id="tODRules"
        class="Rules hidden"
      >
        <div class="closeBtn">
          <img
            src="../assets/Icons/close.svg"
            alt=""
            class="redBtn"
            @click="helpmenu()"
          >
        </div>
        <h4 class="font-bold">
          Erotik für Paare Spielregeln
        </h4><br>
        <span class="font-normal">Wähle zwischen Dirty Talk oder Pflicht aus. Per Zufall wird dir eine Aufgabe oder Frage gestellt, die du absolvieren musst. Sollte sich ein Spieler weigern, die Aufgabe zu erfüllen oder die Frage zu beantworten, muss diese Person 2 Schlücke trinken, falls nicht anders in der Aufgabe vorgegeben. Danach ist der nächste Spieler an der Reihe.
        </span><br><br>
      </div>
    </div>
    <div class="col-sp-6 row-sp-12 t-right">
      <router-link
        to="gameOverview"
        class="backCloseButton"
      >
        <img
          src="/assets/Icons/close.svg"
        >
      </router-link>
    </div>
  </header>
  <section
    id="erotikFuerPaare"
    class=""
  >
    <div class="headline">
      <h1 class="font-bold">
        {{ Title }}
      </h1>
    </div>
    <h3
      id="EfPtoDo"
      class="font-bold"
    >
      Drücke einen der Button um zu starten
    </h3>
    <div class="gameMenu">
      <div class="btns">
        <button
          id="dirtyTalk-btn"
          @click="dirtyTalkBtn()"
        >
          Dirty Talk
        </button>
        <p class="font-normal pad-8">
          oder
        </p>
        <button
          id="EfPpflicht-btn"
          @click="EfPpflichtBtn()"
        >
          Pflicht
        </button>
      </div>
      <div class="btns">
        <button
          id="EfPzufall-btn"
          @click="EfPzufallsBtn()"
        >
          Zufall
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
import json from '@/jsonDaten/WoP.JSON'
const EfPTL = new gsap.timeline()
export default {
  name : "ErotikFuerPaare",
  title : "ErotikFuerPaare",
    data(){
        return{
            WoPDaten : json,
            Title: 'Dirty Talk oder Pflicht'
        }
    },
    methods: {
      helpmenu : function(){
        document.querySelector("#tODRules").classList.toggle("hidden");
      },
        dirtyTalkBtn : function(){
            this.renderElements(this.WoPDaten,"wahrheit")
            this.Title = 'Wahrheit'
        },
        EfPpflichtBtn : function(){
            this.renderElements(this.WoPDaten,"pflicht")
            this.Title = 'Pflicht'
        },
        EfPzufallsBtn : function(){
            const randomizer = Math.round(Math.random() );
            if(randomizer == 0){
                this.dirtyTalkBtn()
            }
            else if(randomizer == 1){
                this.EfPpflichtBtn()
            }
        },

        renderElements : function(jsonDaten, task){
            const toDo = document.querySelector("#EfPtoDo")
            function randomContent(AllContent){
                const randomNumber = [Math.floor(Math.random()*AllContent.length)]
                let question = AllContent[randomNumber]
                if(AllContent.length <= 0){
                    document.querySelector("erotikFuerPaareBanner").classList.add("hidden")
                    document.querySelector("endcardBanner").classList.remove("hidden")
                }
                AllContent.splice(randomNumber, 1)
                console.log(AllContent.length)
                toDo.innerHTML = question
            }
            if(task == "wahrheit"){
                randomContent(jsonDaten.Wahrheit.stufe3.Fragen)
            }
            else if(task == "pflicht"){
                randomContent(jsonDaten.Pflicht.stufe3.Pflichten)       
            }
        }
    }
}
</script>

<style scoped>
html {
    background-color: #BF1F3C !important;
}
.headline h1{
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: end;
    word-spacing: 5px;
    height: 30px;
    white-space: nowrap;
    margin-bottom: 16px;
}
#erotikFuerPaare{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100vh;
    background-color: #BF1F3C

}
.btns{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80vw;

}
#dirtyTalk-btn{
    background-color: #730217;
    width: 210px;
    height: 80px;
    border-style: none;
    color: white;
    font-size: 26px;
    font-weight: bold;
    border-radius: 7px;

}
.btns button{
  cursor: pointer;
}
#EfPpflicht-btn{
    background-color: #F2B263;
    width: 210px;
    height: 80px;
    border-style: none;
    color: white;
    font-size: 26px;
    font-weight: bold;
    border-radius: 7px;
}
#erotikFuerPaare p{
    color: white;
    font-size: 20px;
    font-weight: lighter;
}
#EfPzufall-btn{
    background: linear-gradient(90deg, rgba(115,2,23,1) 0%, rgba(115,2,23,1) 50%, rgba(242,178,99,1) 50%);
    font-size: 20px;
    font-weight: bold;
    color: white;
    border: none;
    padding: 12px 50px;
    border-radius: 8px;
    cursor: pointer;
  margin-top: 16px;
}
#EfPtoDo {
    color: white;
    margin: 0px 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}
</style>