<template>
  <router-view />
  <router-view name="footer" />
  <VueModal
    :show="auth.buyModal"
    @cancel="auth.buyModal = false"
    approve="Kaufen"
    cancel="abbrechen"
  >
    Möchtest du Premium für x.xx€ kaufen?
  </VueModal>
</template>
    <script>
      import {authStore} from "@/store/authStore";
      import {configStore} from "@/store/configStore";
      import {addStore} from "@/store/addStore";

      export default {
      name: 'App',
      setup() {
        const auth = authStore()

        return {auth}
      },
      }
    </script>

    <style>
      @import url('./assets/css/main.css');
      html {
      background-color: #282d4e;
      }
      main{
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      overflow-x: hidden;
      }
      .center{
      display: flex;
      flex-direction: column;
      align-items: center;
      }
      .Rules{
      color: white;
      background-color: #545C8F;
      width: 90vw;
      padding: 10px;
      padding-bottom: 100px;
      border-radius: 20px;
      position: absolute;
      top: 70px;
      left: calc(5vw - 10px);
      z-index: 30;
      text-align: start;
      }
      .Rules h4{
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      }
      .Rules h5{
      font-weight: bold;
      font-size: 20px;
      }
      strong{
      font-weight: bold;
      }
      .closeBtn{
      }
      .redBtn{
      width: 40px;
      height: 40px;
      }
      .helpsymbol {
      height: 30px;
      width: 30px;
      filter: invert(1);
      margin-bottom: 5px;
      }
    </style>