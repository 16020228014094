import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router'
import homepage from "@/views/Home";
import main from "@/components/navBar/main";
import footer from "@/components/navBar/footer";
import kingsCup from "@/views/KingsCup";
import game from "@/components/navBar/game";
import scharade from "@/views/Scharade";
import settings from "@/views/Settings";
import admintool from "@/views/Admintool";
import bombig from "@/views/Bombig";
import drinkOrLose from "@/views/DrinkOrLose";
import endcard from "@/views/Endcard";
import entwederOder from "@/views/EntwederOder";
import erotikFuerPaare from "@/views/ErotikFuerPaare";
import flaschendrehen from "@/views/Flaschendrehen";
import gameOverview from "@/views/GameOverview";
import gaytabu from "@/views/Gaytabu";
import ichHabNochNie from "@/views/IchHabNochNie";
import pantomime from "@/views/Pantomime";
import StadtLandSpeed from "@/views/StadtLandSpeed";
import twinkOrDaddy from "@/views/twinkOrDaddy";
import wahrheitOderPflicht from "@/views/wahrheitOderPflicht";
import werBinIch from "@/views/WerBinIch";
import werInDiesemRaum from "@/views/werInDiesemRaum";
import {configStore} from "@/store/configStore";
import {authStore} from "@/store/authStore";

const routes = [
   {
     path: '/',
     name: 'Home',
     components: {
       navbar: main,
       default: homepage,
       footer: footer
     }
   },
    {
        path: '/settings',
        name: 'Settings',
        components: {
            navbar: main,
            default: settings,
            footer: footer
        }
    },
    {
        path: '/admintool',
        name: 'Admintool',
        components: {
            navbar: main,
            default: admintool,
            footer: footer
        }
    },
   {
     path: '/bombig',
     name: 'Bombig',
     components: {
       navbar: game,
       default: bombig
     }
   },
   {
     path: '/drinkorlose',
     name: 'DrinkOrLose',
     components: {
       navbar: game,
       default: drinkOrLose
     }
   },
   {
     path: '/endcard',
     name: 'Endcard',
     components: {
       navbar: game,
       default: endcard
     }
   },
   {
     path: '/entwederoder',
     name: 'EntwederOder',
     components: {
       navbar: game,
       default: entwederOder
     }
   },
   {
     path: '/erotikfuerpaare',
     name: 'ErotikFuerPaare',
     components: {
       navbar: game,
       default: erotikFuerPaare
     },
     meta: { premium: true }
   },
   {
     path: '/flaschendrehen',
     name: 'FlaschenDrehen',
     components: {
       navbar: game,
       default: flaschendrehen
     },
     meta: { premium: true }
   },
   {
     path: '/gameoverview',
     name: 'GameOverview',
     components: {
       navbar: game,
       default: gameOverview
     }
   },
   {
     path: '/gaytabu',
     name: 'GayTabu',
     components: {
       navbar: game,
       default: gaytabu
     },
     meta: { premium: true }
   },
   {
     path: '/ichhabnochnie',
     name: 'IchHabNochNie',
     components: {
       navbar: game,
       default: ichHabNochNie
     }
   },
   {
     path: '/kingscup',
     name: 'KingsCup',
     components: {
       navbar: game,
       default: kingsCup
     },
     meta: { premium: true }
   },
   {
     path: '/pantomime',
     name: 'Pantomime',
     components: {
       navbar: game,
       default: pantomime
     }
   },
   {
     path: '/scharade',
     name: 'Scharade',
     components: {
       navbar: game,
       default: scharade
     }
   },
   {
     path: '/stadtlandspeed',
     name: 'StadtLandSpeed',
     components: {
       navbar: game,
       default: StadtLandSpeed
     }
   },
   {
     path: '/twinkordaddy',
     name: 'TwinkOrDaddy',
     components: {
       navbar: game,
       default: twinkOrDaddy
     },
     meta: { premium: true }
   },
   {
     path: '/wahrheitoderpflicht',
     name: 'WahrheitOderPflicht',
     components: {
       navbar: game,
       default: wahrheitOderPflicht
     }
   },
   {
     path: '/werbinich',
     name: 'WerBinIch',
     components: {
       navbar: game,
       default: werBinIch
     }
   },
   {
     path: '/werindiesemraum',
     name: 'WerInDiesemRaum',
     components: {
       navbar: game,
       default: werInDiesemRaum
     }
   },
   {
     path: '/:pathMatch(.*)*',
     alias: '/404',
     name: '404',
     redirect: () => {
       return { path: '/' }
     },
   }
 ]

const router = createRouter({
  history: (process.env.CORDOVA_PLATFORM === undefined) ? createWebHistory(process.env.BASE_URL) : createWebHashHistory(),
  routes
})

router.beforeEach((to, from) => {
  const auth = authStore()
  const config = configStore()

  config.dropDown = false
  config.loading = true

  window.scrollTo(0, 0)

  if (to.matched.some(record => record.meta.premium)) {
    if(!auth.premium) return false
  }
  return true

})

export default router