
<template>
  <div class="center vh100">
    <div
      id="pantomimeRules"
      class="Rules hidden"
    >
      <div class="closeBtn">
        <img
          src="/assets/Icons/close.svg"
          alt=""
          class="redBtn"
          @click="helpmenu()"
        >
      </div>
      <h4 class="font-bold">
        Pantomime Spielregeln
      </h4><br>
      <span class="font-normal">Ein Spieler zieht einen Begriff und erklärt ihn seinen Freunden pantomimisch, also ohne Worte und Geräusche. Nur mit Bewegungen seines Körpers, seiner Hände und Füße. Derjenige, der das Wort zuerst richtig errät, darf 2 Schlücke verteilen.
      </span><br><br>
    </div>
    <header class="GameHeader grid-12">
      <div class="col-sp-6 row-sp-12 t-left">
        <img
          src="/assets/Icons/help.svg"
          alt=""
          class="helpsymbol"
          @click="helpmenu()"
        >
      </div>
      <div class="col-sp-6 row-sp-12 t-right">
        <router-link
          to="gameOverview"
          class="backCloseButton"
        >
          <img
            src="/assets/Icons/close.svg"
          >
        </router-link>
      </div>
    </header>
    <h1
      style="position: absolute; width: 100%; top: 20px"
      class="font-bolder color-white t-center pad-top-16"
    >
      Pantomime
    </h1>
    <section class="container">
      <h2
        id="pantomimeTask"
        class="font-bold"
        @click="scharadeWord()"
      >
        Klicke <span style="color: #FFA301;">hier</span> um das erste Wort zu bekommen
      </h2>
      <h3 class="font-normal">
        Pantomimisch darstellen
      </h3>
      <div class="gameMenu">
        <div class="centerTimer">
          <h2 id="pantomimeBackgroundTimer" />
        </div>
        <button
          id="pantomimeTimerStartBtn"
          @click="startTimer()"
        >
          Timer Starten
        </button>
        <button
          id="pantomimeTimerStopBtn"
          class="hidden"
          @click="stopTimer()"
        >
          Timer Stoppen
        </button>
        <button @click="scharadeWord">
          neues Wort
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import json from '@/jsonDaten/pantomime.JSON';
import {addStore} from "@/store/addStore";
import {authStore} from "@/store/authStore";

export default {
  name: "GamePantomime",
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
  data(){
    return{
      scharadeDaten: json,
      time: 60,
      timerInterval: null,
    }
  },
  beforeUnmount() {
    try {
      clearInterval(this.timerInterval)
    }catch (e) {

    }
  },
  methods :{
    scharadeWord : function(){
      const scharadeTask = document.querySelector("#pantomimeTask");
      scharadeTask.innerHTML=this.getRandomItem(this.scharadeDaten.Begriff);
      return
    },
    helpmenu : function(){
      document.querySelector("#pantomimeRules").classList.toggle("hidden");
    },
    getRandomItem : function(array){
      const randomIndex = Math.floor(Math.random() * array.length);
      if(array.length <=0){
            document.querySelector("#pantomimeBanner").classList.add("hidden")
            document.querySelector("#endCardKC").classList.remove("hidden")
            document.querySelector("#endCardPantomime").classList.add("hidden")
            document.querySelector("#endCardBombig").classList.remove("hidden")
            document.querySelector("#endcardBanner").classList.remove("hidden")
            return
      }
      array.splice(randomIndex, 1)
      const item = array[randomIndex];
      return item;
    },
    startTimer() {
      document.querySelector("#pantomimeBackgroundTimer").innerHTML = this.time
      this.timerInterval = setInterval(() =>{
        this.time -= 1
        document.querySelector("#pantomimeBackgroundTimer").innerHTML = this.time
        if (this.time === 0){
            clearInterval(this.timerInterval);
        }
      }, 1000);
      document.querySelector("#pantomimeTimerStartBtn").classList.add("hidden")
      document.querySelector("#pantomimeTimerStopBtn").classList.remove("hidden")
    },
    stopTimer : function(){
      clearInterval(this.timerInterval);
      this.time = 60
      document.querySelector("#pantomimeTimerStartBtn").classList.remove("hidden")
      document.querySelector("#pantomimeTimerStopBtn").classList.add("hidden")
    },
  }

}
</script>
<style scoped>
.vh100{
  height: 100vh;
  justify-content: space-around;
}
h2{
  font-size: 28px;
  padding: 16px;
  z-index: 20;
}
h3{
  font-size: 20px;
}
h2 span{
  font-size: 1em;
}
.gameMenu button {
  border-style: none;
  border-radius: 7px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  width: 175px;
  height: 38px;
  background-color: #FFA301;
  color: white;
  margin: 10px;
  z-index: 20;
}
.backToHome {
  filter: invert(1);
  position: absolute;
  top: 10px;
  left: 10px;
}
.centerTimer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  overflow: hidden;
}
#pantomimeBackgroundTimer{
    font-size: 300px;
    font-family: 'open sans';
    font-weight: bold;
    color: rgba(255, 255, 255, 0.2);
}
.container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
  text-align: center;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}

</style>