<template>
  <div
    id="gayTabuRules"
    class="Rules hidden"
  >
    <div class="closeBtn">
      <img
        src="../assets/Icons/close.svg"
        alt=""
        class="redBtn"
        @click="helpmenu()"
      >
    </div>
    <h4 class="font-bold">
      Pride Scharade Spielregeln
    </h4><br>
    <span class="font-normal">Ein Spieler zieht einen Begriff und erklärt ihn seinen Freunden wörtlich, ohne das Wort selbst zu nennen oder Teile des Wortes zu benutzen. Derjenige, der das Wort zuerst richtig errät, darf 2 Schlücke verteilen.
    </span><br><br>
  </div>
  <header class="GameHeader grid-12">
    <div class="col-sp-6 row-sp-12 t-left">
      <img
        src="/assets/Icons/help.svg"
        alt=""
        class="helpsymbol"
        @click="helpmenu()"
      >
    </div>
    <div class="col-sp-6 row-sp-12 t-right">
      <router-link
        to="gameOverview"
        class="backCloseButton"
      >
        <img
          src="/assets/Icons/close.svg"
        >
      </router-link>
    </div>
  </header>
  <h1
    style="position: absolute; width: 100%;"
    class="font-bolder color-white t-center pad-top-16"
  >
    Pride Scharade
  </h1>
  <div class="container">
    <h2
      id="gayTabuTask"
      class="font-bold"
      @click="gayTabuWord()"
    >
      Klicke <span style="color: #FFA301;">hier</span> um das erste Wort zu bekommen
    </h2>
    <h3 class="font-normal">
      Beschreiben / Nachmachen
    </h3>
    <div class="gameMenu">
      <div class="centerTimer">
        <h2 id="gayTabuBackgroundTimer" />
      </div>
      <button
        id="gayTabuTimerStartBtn"
        @click="startTimer()"
      >
        Timer Starten
      </button>
      <button
        id="gayTabuTimerStopBtn"
        class="hidden"
        @click="stopTimer()"
      >
        Timer Stoppen
      </button>
      <button @click="gayTabuWord">
        neues Wort
      </button>
    </div>
  </div>
</template>

<script>
import json from '@/jsonDaten/gaytabu.json';

export default {
  name: "GayTabu",
  title: "Gay Tabu",
  data(){
    return{
      scharadeDaten: json,
      time: 60,
      timerInterval: null,
    }
  },
  beforeUnmount() {
    try {
      clearInterval(this.timerInterval)
    }catch (e) {

    }
  },
  methods :{
    helpmenu : function(){
        document.querySelector("#gayTabuRules").classList.toggle("hidden");
    },
    gayTabuWord : function(){
      const scharadeTask = document.querySelector("#gayTabuTask");
      scharadeTask.innerHTML=this.getRandomItem(this.scharadeDaten.stufe1);
      return
    },
    getRandomItem : function(array){
      const randomIndex = Math.floor(Math.random() * array.length);
      array.splice(randomIndex, 1)
      if(array.length <= 0){
        document.querySelector("gayTabuBanner").classList.add("hidden")
        document.querySelector("endcardBanner").classList.remove("hidden")
      }
      const item = array[randomIndex];
      return item;
    },
    startTimer() {
      document.querySelector("#gayTabuBackgroundTimer").innerHTML = this.time
      this.timerInterval = setInterval(() =>{
        this.time -= 1
        document.querySelector("#gayTabuBackgroundTimer").innerHTML = this.time
        if (this.time === 0){
            clearInterval(this.timerInterval);
        }
      }, 1000);
      document.querySelector("#gayTabuTimerStartBtn").classList.add("hidden")
      document.querySelector("#gayTabuTimerStopBtn").classList.remove("hidden")
    },
    stopTimer : function(){
      clearInterval(this.timerInterval);
      this.time = 60
      document.querySelector("#gayTabuTimerStartBtn").classList.remove("hidden")
      document.querySelector("#gayTabuTimerStopBtn").classList.add("hidden")
    },
  }

}
</script>
<style scoped>
h2 span{
  font-size: 1em;
}
h3 {
  margin: 40px 0 0;
}
.container button {
  border-style: none;
  border-radius: 7px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  width: 175px;
  height: 38px;
  background-color: #FFA301;
  color: white;
  margin: 10px;
}
.backToHome {
  filter: invert(1);
  position: absolute;
  top: 10px;
  left: 10px;
}
#gayTabuBackgroundTimer{

    font-size: 300px;
    font-family: 'open sans';
    font-weight: bold;
    color: rgba(255, 255, 255, 0.2);
}
#gayTabuTask {
  z-index: 20;
}
.container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
  text-align: center;
}
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}
</style>