<template>
  <div
    id="ichHabNochNieRules"
    class="Rules hidden"
  >
    <div class="closeBtn">
      <img
        src="/assets/Icons/close.svg"
        alt=""
        class="redBtn"
        @click="helpmenu()"
      >
    </div>
    <h4 class="font-bold">
      Ich hab noch nie Spielregeln
    </h4><br>
    <span class="font-normal">Das Spiel beginnt mit den „Ich hab noch nie“-Fragen und alle Freunde, die es schon mal getan haben, müssen 1 Schluck trinken. Danach wird die nächste Ich hab noch nie Frage in die Runde gestellt.
    </span><br><br>
  </div>
  <section
    id="IchHabNochNie"
    class="theme-drinkorlose"
  >
    <div id="stufenAbfrage">
      <header>
        <div class="t-left pad-16">
          <router-link to="/gameOverview">
            <img
              src="../assets/Icons/back.svg"
              class="width-32"
              style="filter: invert(1)"
            >
          </router-link>
        </div>
        <div class="pad-16" />
      </header>
      <div class="spielStufen">
        <div
          id="stufe1"
          class="stufenImg stufeWarmUp"
          @click="getStufe('stufe1')"
        >
          <div class="StufenBeschreibung">
            <h3>Text für Spießer und Vergebene</h3>
            <h2>Warm Up</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/flexed-biceps.png"
            class="width-64 stufenIcon"
          >
        </div>
        <div
          id="stufe2"
          class="stufenImg stufeDirty"
          @click="getStufe('stufe2')"
        >
          <div class="StufenBeschreibung">
            <h3>Für den aufregenden Spaß</h3>
            <h2>Dirty</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/fire.png"
            class="width-64 stufenIcon"
          >
        </div>
        <div
          id="stufe3"
          class="stufenImg stufeFun"
          @click="getStufe('stufe3')"
        >
          <div class="StufenBeschreibung">
            <h3>Hakt eure To Do Liste ab</h3>
            <h2>Spaß</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/face-with-hand-over-mouth.png"
            class="width-64 stufenIcon"
          >
        </div>
        <div
          id="stufe4"
          class="stufenImg stufeSecrets"
          @click="getStufe('stufe4')"
        >
          <div class="StufenBeschreibung">
            <h3>Neuer Gossip mit allen Details</h3>
            <h2>Sex Beichten</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/shushing-face.png"
            class="width-64 stufenIcon"
          >
        </div>
        <div
          id="stufe5"
          class="stufenImg stufeLoveGame"
          @click="getStufe('stufe5')"
        >
          <div class="StufenBeschreibung">
            <h3>Hier darfst du keine Hemmungen haben</h3>
            <h2>Love Game</h2>
          </div>
          <img
            src="../assets/Pictures/emojis/heart-on-fire.png"
            class="width-64 stufenIcon"
          >
        </div>
      </div>
      <div class="pad-16 t-center">
        <button
          @click="helpmenu()"
          class="button large secondary"
        >
          Spieleanleitung
        </button>
      </div>
    </div>
    <div
      id="iHNNgameArea"
      class="gameArea hidden"
    >
      <header class="GameHeader grid-12">
        <div class="col-sp-6 row-sp-12 t-left">
          <img
            src="/assets/Icons/help.svg"
            alt=""
            class="helpsymbol"
            @click="helpmenu()"
          >
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <router-link
            to="gameOverview"
            class="backCloseButton"
          >
            <img
              src="/assets/Icons/close.svg"
            >
          </router-link>
        </div>
      </header>
      <h1 class="headline font-bold">
        Ich hab noch nie
      </h1>
      <h3
        id="toDoText"
        class="font-normal"
      >
        Drücke auf "Weiter" um zu beginnen
      </h3>
      <div class="gameMenu">
        <button
          id="weiter"
          @click="getRandomItem(jsonDaten)"
        >
          Weiter
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import json from '@/jsonDaten/ichHabNochNie.json'
import {authStore} from "@/store/authStore";
import {addStore} from "@/store/addStore";
let stufe = null
export default {
  name: "IchHabNochNie",
  title: "Ich hab noch nie",
  setup() {
    const add = addStore()
    const auth = authStore()

    return {add, auth}
  },
    data(){
        return{
            jsonDaten : json
        }
    },
    methods: {
        getStufe : function(value){
            stufe = value
            console.log(stufe)
            switch(stufe){
                case "stufe1":
                    console.log(stufe)
                    break;
                case "stufe2":
                    if(this.auth.premium){
                        console.log("Premium vorhanden")
                        break;
                    }
                    else{
                        console.log("kauf dir Premium");
                        return
                    }
                case "stufe3":
                    if(this.auth.premium){
                        console.log("Premium vorhanden")
                        break;
                    }
                    else{
                        console.log("kauf dir Premium");
                        return
                    }
                case "stufe4":
                    if(this.auth.premium){
                        console.log("Premium vorhanden")
                        break;
                    }
                    else{
                        console.log("kauf dir Premium");
                        return
                    }
                case "stufe5":
                    if(this.auth.premium){
                        console.log("Premium vorhanden")
                        break;
                    }
                    else{
                        console.log("kauf dir Premium");
                        return
                    }
            }
            document.querySelector("#stufenAbfrage").classList.add("hidden")
            document.querySelector("#iHNNgameArea").classList.remove("hidden")
            return stufe
        },
        helpmenu : function(){
            document.querySelector("#ichHabNochNieRules").classList.toggle("hidden");
        },
        getRandomItem : function(jsonDaten){
            function randomContent(AllContent){
            if(AllContent.length <= 0){
                    document.querySelector("ichHabNochNieBanner").classList.add("hidden")
                    document.querySelector("endcardBanner").classList.remove("hidden")
                }    
                const randomNumber = [Math.floor(Math.random()*AllContent.length)]
                let question = AllContent[randomNumber]
                AllContent.splice(randomNumber, 1)
                document.querySelector("#toDoText").innerHTML = question
            }
            switch (stufe){
                case "stufe1":
                   randomContent(jsonDaten.stufe1)
                    break;
                case "stufe2":
                    randomContent(jsonDaten.stufe2)
                    break;
                case "stufe3":
                    randomContent(jsonDaten.stufe3)
                    break;
                case "stufe4":
                    randomContent(jsonDaten.stufe4)
                    break;
                case "stufe5":
                    randomContent(jsonDaten.stufe5)
                    break;
            }

        }

    }
}
</script>

<style scoped>
.back-arrow{
    z-index: 25;
}
    #stufenAbfrage{
        position: absolute;
        z-index: 20;
        width: 100%;
        height: fit-content;
    }

    .gameArea{
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height:100vh;
    }
    .headline {
        font-family: 'open sans';
        font-size: 24px;
        font-weight: bold;
    }
    #toDoText{
        font-weight: 600;
        font-family: 'open sans';
        font-size: 30px;
        text-align: center;
        margin: 50px;
    }
    #weiter{
        width: 130px;
        height: 35px;
        font-family: 'open sans';
        font-weight: bold;
        font-size: 20px;
        border-style: none;
        border-radius: 7px;
        background-color: #FFA301;
        color: white;
    }
.gameMenu{
  position: fixed;
  bottom: 5vh;
  text-align: center;
}
</style>